import { HexakaiGameDifficulty } from "../models/hexakai-game-params";

export const DIFFICULTY_TEXTS = {
    [HexakaiGameDifficulty.easy]: `
        In easy mode, only a few cells are empty. The high number of non-empty cells will give you plenty of 
        information to work with when figuring out which values belong to each empty cell.
        Easy mode is perfect for learning how the game works on lower sized boards, and for working your way up
        to larger sized boards.
    `,
    [HexakaiGameDifficulty.medium]: `
        In medium mode, roughly 42% of the cells on the board are empty. The nearly even ratio of empty to non-empty cells gives
        a nice, simple playing experience: enough cells are missing to make you focus, but enough cells are present to
        give you enough information to solve the board relatively quickly. This is a good step up from easy mode without
        becoming too challenging.
    `,
    [HexakaiGameDifficulty.difficult]: `
        In difficult mode, anywhere from 45% to 57% of the cells are empty. With so much information hidden, you'll need to begin
        employing more advanced strategies to make definite assignments to the missing cells. You'll also need to identify which cells,
        rows, and diagonals should be tackled on priority, as some choices will help you find missing values faster, while others
        will cause the game to drag on. This mode is perfect for those who want a real challenge, so long as the board size is reasonably high.
    `,
    [HexakaiGameDifficulty.ultraDifficult]: `
        In ultra-difficult mode, 58% or more of the cells are empty, approaching the theoretical limit of how many cells we can
        remove while still producing a valid game of Hexakai. Despite the relatively small increase in percentage, the
        difficulty gap between difficult and ultra-difficult is significantly high. On ultra-difficult puzzles, you'll have no
        choice but to carefully employ multiple strategies of varying complexity to even begin to make real headway. Careful prioritization
        of rows and diagonals is a must. This mode is perfect for those who enjoy complex, seemingly impossible challenges, and those
        who seek mastery over this game, so long as the board size is reasonably high.
    `,
}