export function scrapeDate(text: string): string {
    // format, Month name, month day, short or long year
    const monthRegex = /(jan(uary)?|feb(ruary)?|mar(ch)?|apr(il)?|may|june|july|aug(ust)?|sep(tember)?|oct(ober)?|nov(ember)?|dec(ember)?)/gmi;
    const dayRegex = /[0-3]?([0-9]+|(fir|seco|thi|four|fif|six|seven|eig|nin))(st|nd|rd|th)?/gmi;
    const yearRegex = /([0-9][0-9])?[0-9][0-9]/;

    const naturalLanguageRegex = new RegExp(
        `${monthRegex.source}\\s+${dayRegex.source}\\s*,?\\s+${yearRegex.source}`,
        "gmi"
    );
    const naturalMatch = text.match(naturalLanguageRegex);
    if (naturalMatch) {
        const monthMatch = naturalMatch[0].match(monthRegex)!;
        naturalMatch[0] = naturalMatch[0].replace(monthMatch[0], "");

        const dayMatch = naturalMatch[0].match(dayRegex)!;
        naturalMatch[0] = naturalMatch[0].replace(dayMatch[0], "");
        let day = dayMatch[0].replace(/[a-zA-Z]/g, "");

        const yearMatch = naturalMatch[0].match(yearRegex)!;
        let year = yearMatch[0];
        if (year.length === 2) {
            year = `20${year}`;
        }

        let month = -1;
        switch (monthMatch[0].toLowerCase().substring(0, 3)) {
            case "jan": month = 1; break;
            case "feb": month = 2; break;
            case "mar": month = 3; break;
            case "apr": month = 4; break;
            case "may": month = 5; break;
            case "jun": month = 6; break;
            case "jul": month = 7; break;
            case "aug": month = 8; break;
            case "sep": month = 9; break;
            case "oct": month = 10; break;
            case "nov": month = 11; break;
            case "dec": month = 12; break;
        }

        if (month > -1) {
            return `${year}-${month}-${day}`;
        }
    }

    // format: YYYY-MM-DD
    const sysRegex = /[0-9][0-9][0-9][0-9]-[0-2]?[0-9]-[0-3]?[0-9]/;
    const sysMatch = text.match(sysRegex);
    if (sysMatch) {
        const [y, m, d] = sysMatch[0].split("-");
        const year = parseInt(y);
        const month = parseInt(m);
        const day = parseInt(d);

        return `${year}-${month}-${day}`;
    }

    // format: MM/DD/YY(YY)?
    const stdRegex = /(0|1)?[0-9](\/|-)(0|1|2|3)?[0-9](\/|-)[0-9][0-9]([0-9][0-9])?/gmi;
    const stdMatch = text.match(stdRegex);
    if (stdMatch) {
        const delimiter = stdMatch[0].indexOf("-") > -1 ? "-" : "/";
        const [m, d, y] = stdMatch[0].split(delimiter);
        const year = parseInt(y);
        const month = parseInt(m);
        const day = parseInt(d);

        return `${year}-${month}-${day}`;
    }

    return '';
}