import { html } from "lit";
import { ModalService } from "../../modal-service/modal-service";
import { BaseAdProviderService } from "../base-ad-provider";
import { ClientService } from "../../client-service/client-service";

export interface AdSenseAttributes {
    client: string;
    slot: string;
    format?: string;
    fullWidthResponsive?: boolean;
    style?: string;
}

export class GoogleAdsProviderService extends BaseAdProviderService {

    public static AD_UNITS = {
        primaryHorizontal: {
            client: 'ca-pub-9209262110227467',
            slot: '5536577993',
            format: 'auto',
            fullWidthResponsive: true
        },
        fixed400: {
            client: 'ca-pub-9209262110227467',
            slot: '3781928025',
            style: "display:inline-block;width:400px;height:90px"
        }
    };

    private adSlotModalId = "ad-slot-modal";

    constructor(
        viewPercentage: number,
        private modalService: ModalService,
        private clientService: ClientService
    ) {
        super(viewPercentage);

        this.clientService.onInnerNavigationOccurred(() => {
            const adContainers = document.querySelectorAll(".google-ads-container");
            for (const ad of adContainers) {
                const el = (ad as HTMLElement);
                const attr = JSON.parse(el.dataset.adAttributes!.replace(/'/g, "\""));
                el.innerHTML = "";
                setTimeout(() => {
                    const template = GoogleAdsProviderService.getAdMarkup(attr, false);
                    el.innerHTML = template;
                    ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
                }, 100);
            }
        });
    }

    protected async doInitialize(delay: number): Promise<void> {
        return new Promise((resolve, reject) => {
            const adUnit = GoogleAdsProviderService.AD_UNITS.primaryHorizontal;
            setTimeout(async () => {
                try {
                    const { markup, renderAd } = await this.loadAdUnit(adUnit);
                    await this.renderAdModal(adUnit.slot, markup, renderAd);
                    resolve();
                } catch (err) {
                    console.log(`[GoogleAdsProviderService] error caught, so rejecting:`, err);
                    reject(err);
                }
            }, delay);
        });
    }

    private renderAdModal(adSlot: string, markup: any, renderAd: Function): Promise<void> {
        return new Promise((resolve, reject) => {
            console.log(`[GoogleAdsProviderService] rendering ad`);

            const fullMarkup = html`
                <style>
                    .ad-container {
                        width: 100%;
                    }
                </style>
                <div class="ad-container">
                    ${markup}
                </div>
            `;

            this.modalService.showModal(
                this.adSlotModalId,
                'A word from our sponsors:',
                fullMarkup,
                {
                    maxWidth: '800px'
                }
            );

            // TODO: this is hacky
            setTimeout(() => {
                renderAd();
            }, 200);

            //(window as any).renderAd = renderAd;

            setTimeout(() => {
                const didLoad = this.ensureAdRendered(adSlot);
                if (didLoad) {
                    resolve();
                } else {
                    this.modalService.hideModal(this.adSlotModalId);
                    reject("Google ad did not load");
                }
            }, 3_000);
        });
    }

    private async loadAdUnit(adAttributes: AdSenseAttributes): Promise<{ markup: any, renderAd: () => void }> {
        return new Promise(async (resolve, reject) => {
            try {
                await GoogleAdsProviderService.attachGoogleAdsScript(adAttributes)
                const markup = html`${GoogleAdsProviderService.getAdMarkup(adAttributes)}`;

                // Callback function to render the ad once ready
                const renderAd = () => {
                    console.log(`[GoogleAdsProviderService] invoking ad render`);
                    // Push the adsbygoogle request to display the ad
                    ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
                };

                // Resolve the promise with both the markup and the callback
                resolve({ markup, renderAd });
            } catch (err) {
                reject(new Error('Failed to load AdSense script'));
            }
        });
    }

    private ensureAdRendered(adSlot: string): boolean {
        const adElement = document.querySelector(`ins[data-ad-slot="${adSlot}"]`);
        if (!adElement) {
            console.warn(`[GoogleAdsProviderService] Couldn't find the ad slot for ${adSlot}`);
            return false;
        }

        if (adElement.getAttribute('data-ad-status') === 'unfilled') {
            console.warn('[GoogleAdsProviderService] AdSense ad unfilled. Displaying fallback.');
            return false
        }

        if (adElement.getBoundingClientRect().height === 0) {
            console.warn('[GoogleAdsProviderService] AdSense ad has zero height. Displaying fallback.');
            return false
        }

        console.log(`[GoogleAdsProviderService] verified that ad slot loaded correctly`);
        return true;
    }

    public static attachGoogleAdsScript(adAttributes: AdSenseAttributes): Promise<void> {
        // Create script element for the AdSense JavaScript
        const adScript = document.createElement('script');
        adScript.async = true;
        adScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
        adScript.setAttribute('data-ad-client', adAttributes.client);
        adScript.crossOrigin = 'anonymous';

        // Append the script to the head or body
        document.head.appendChild(adScript);
        console.log("[GoogleAdsProviderService] Google Adsense Script Attached");

        return new Promise((resolve, reject) => {
            adScript.onload = () => resolve();
            adScript.onerror = (err) => reject(err);
        })
    }

    public static getAdMarkup(adAttributes: AdSenseAttributes, asTemplate = true): any {
        let template = html``;

        const attrJson = JSON.stringify(adAttributes).replace(/\"/g, "'");
        if (adAttributes.style) {
            template = html`
                <div class="google-ads-container" data-ad-attributes="${attrJson}">
                    <ins class="adsbygoogle"
                        style="display:block"
                        data-ad-client="${adAttributes.client}"
                        data-ad-slot="${adAttributes.slot}"
                        data-ad-format="${adAttributes.format || ''}"
                        data-full-width-responsive="${adAttributes.fullWidthResponsive ? 'true' : ''}"
                        style="${adAttributes.style || ''}"
                    >
                    </ins>
                </div>
        `;
        } else {
            template = html`
                <div class="google-ads-container" data-ad-attributes="${attrJson}">
                    <ins class="adsbygoogle"
                        style="display:block"
                        data-ad-client="${adAttributes.client}"
                        data-ad-slot="${adAttributes.slot}"
                        data-ad-format="${adAttributes.format || ''}"
                        data-full-width-responsive="${adAttributes.fullWidthResponsive ? 'true' : ''}"
                    >
                    </ins>
                </div>
            `;
        }


        if (asTemplate) {
            return template;
        }

        const originalStrings = template.strings;
        const values = template.values;

        // Combine the strings and values to reconstruct the full template
        let fullTemplate = '';
        for (let i = 0; i < originalStrings.length; i++) {
            fullTemplate += originalStrings[i];
            if (i < values.length) {
                fullTemplate += values[i];
            }
        }

        return fullTemplate;
    }
}