import { ClientService } from "../client-service/client-service";
import { GlobalAccessService } from "../global-access-service/global-access-service";
import { HexakaiBoardData } from "../hexakai-board/hexakai-board-data";
import { HexakaiGameBoardCreator } from "../models/hexakai-game-board-creator";
import { HexakaiGameDifficulties, HexakaiGameDifficulty, HexakaiGameParams } from "../models/hexakai-game-params";
import { HexakaiGameSession } from "../models/hexakai-game-session";
import { RngType } from "../models/rng-params";
import { getRandomInvoker } from "../random/get-random";
import { MkAleaRng } from "../random/rngs/mk-alea.rng";
import { DAILY_PUZZLE_GENERATOR_PROFILES } from "./daily-puzzle-generator-patterns-v1";

/**
 * Create a unique puzzle based on the time of day
 */
export class DailyPuzzleService {

    public static FIRST_TIMESTAMP = 1720584000000;
    public static DAY_DURATION = (86400 * 1000);

    private algorithmV3CutoffTimestampLower = new Date('2024-07-01T20:23:06.859Z');

    private algorithmV1CutoffTimestampUpper = new Date('2024-10-03T20:23:06.859Z');
    private algorithmV2CutoffTimestampUpper = new Date('2024-11-02T17:33:32.104Z');
    private algorithmV3CutoffTimestampUpper = new Date('2025-01-23T17:33:32.104Z');

    private dailyPuzzlePatternV1CutoffTimestamp = new Date('2024-10-12T23:46:47.895Z');

    private rngParamsAleaCutoffTimestamp = this.algorithmV3CutoffTimestampUpper;

    // make median values more likely than edge values
    // different values stand for different time ranges as defined below
    // intent is to preserve backward compatability
    private SIZE_RANGE_1 = [
        7,
        8, 8,
        9, 9, 9,
        10, 10, 10, 10,
        11, 11, 11,
        12, 12,
        13
    ];
    private SIZE_RANGE_2 = [
        7,
        8, 8,
        9, 9, 9,
        10, 10, 10, 10, 10, 10,
        11, 11, 11,
        12, 12,
        13
    ];

    private sizeRange1CuttofTimestampUpper = this.algorithmV3CutoffTimestampUpper;

    private puzzleCache = new Map<number, HexakaiGameSession>();

    constructor(
        private boardCreator: HexakaiGameBoardCreator,
        private clientService: ClientService
    ) {
        GlobalAccessService.getInstance().registerGlobalHandlers({
            dailyPuzzle: {
                getPuzzleSession: this.getPuzzleSession.bind(this)
            }
        })
    }

    async getPuzzleSession(ts?: number): Promise<HexakaiGameSession> {
        const { timestamp, nextRandom } = this.mapTimestamp(ts);
        console.log("[DailyPuzzleService] generating puzzle for timestamp", ts, timestamp);

        if (this.puzzleCache.has(timestamp)) {
            return this.puzzleCache.get(timestamp)!;
        }

        // create params
        const { gameSize, difficulty } = this.determineBoardParams(timestamp, nextRandom);

        // use appropriate puzzle configuration, hints only for now
        let hintGenerator: any = timestamp;
        if (timestamp > +this.dailyPuzzlePatternV1CutoffTimestamp || timestamp < +this.algorithmV3CutoffTimestampLower) {
            const validGenerators = DAILY_PUZZLE_GENERATOR_PROFILES.filter(p => {
                return p.allowedContexts.find(
                    cxt => cxt.difficulty === difficulty && cxt.gameSize === gameSize
                );
            });

            hintGenerator = validGenerators[
                Math.floor(nextRandom() * validGenerators.length)
            ].hintRandomSeed;

            hintGenerator.seed = timestamp;

            if (hintGenerator.data) {
                hintGenerator.data.gameSize = gameSize;
            }
        }

        // create board
        const board = await this.boardCreator.create(
            {
                gameSize,
                difficulty,
            },
            timestamp,
            hintGenerator,
            this.determineAlgorithmVersion(timestamp),
            timestamp
        );

        const session = {
            params: {
                gameSize,
                difficulty,
            },
            boardState: board.challenge,
            solution: board.solution,
            disabledCells: board.challenge.cells.map(row => {
                return row.map((col, ind) => !!col ? ind : -1).filter(ind => ind > -1)
            }),
            dailyPuzzleTimestamp: timestamp
        };
        this.puzzleCache.set(timestamp, session);
        return session;
    }

    getPuzzleParams(ts?: number): HexakaiGameParams {
        const { timestamp, nextRandom } = this.mapTimestamp(ts);

        if (this.puzzleCache.has(timestamp)) {
            this.puzzleCache.get(timestamp)!.params;
        }

        // create params
        return this.determineBoardParams(timestamp, nextRandom);
    }

    private determineAlgorithmVersion(timestamp: number): number {
        const dT = new Date(timestamp);
        if (dT >= this.algorithmV3CutoffTimestampUpper) {
            return 4;
        }

        if (dT >= this.algorithmV2CutoffTimestampUpper) {
            return 3;
        }

        if (dT >= this.algorithmV1CutoffTimestampUpper) {
            return 2;
        }

        return 1;
    }

    private getDifficultyRange(ts: number): HexakaiGameDifficulty[] {
        // 7/15/24, added easy mode
        // TODO: move to class variables to be in line with others
        if (ts > +this.algorithmV3CutoffTimestampUpper) {
            return [
                HexakaiGameDifficulty.easy,
                HexakaiGameDifficulty.medium,
                HexakaiGameDifficulty.medium,
                HexakaiGameDifficulty.medium,
                HexakaiGameDifficulty.medium,
                HexakaiGameDifficulty.difficult,
                HexakaiGameDifficulty.difficult,
                HexakaiGameDifficulty.difficult,
                HexakaiGameDifficulty.ultraDifficult
            ];
        } else if (ts > 1721016000000) {
            return [
                HexakaiGameDifficulty.easy,
                HexakaiGameDifficulty.medium,
                HexakaiGameDifficulty.medium,
                HexakaiGameDifficulty.difficult,
                HexakaiGameDifficulty.difficult,
                HexakaiGameDifficulty.ultraDifficult
            ];
        } else {
            return [
                HexakaiGameDifficulty.medium,
                HexakaiGameDifficulty.medium,
                HexakaiGameDifficulty.difficult,
                HexakaiGameDifficulty.difficult,
                HexakaiGameDifficulty.ultraDifficult
            ];
        }
    }

    private mapTimestamp(ts?: number): {
        timestamp: number;
        nextRandom: () => number
    } {
        if (!ts) {
            ts = +new Date();
        }

        if (ts > +new Date() && !this.clientService.getConfig().dailyPuzzles.future) {
            ts = +new Date();
        }

        const timestamp = DailyPuzzleService.getStartOfDayTimestamp(ts);

        const params = ts > +this.rngParamsAleaCutoffTimestamp
            ? {
                rngtype: RngType.mersenneTwister,
                seed: [
                    timestamp,
                    253402318800 - timestamp,
                    timestamp % 982451653,
                    Math.sin(timestamp) * 1e9
                ]
            }
            : timestamp;
        
        return {
            timestamp,
            nextRandom: getRandomInvoker(params, true)
        };
    }

    /**
     * Determine board params based on special circumstances, such as holidays, etc.
     */
    private determineBoardParams(ts: number, nextRandom: () => number): HexakaiGameParams {
        // setup random params
        const sizeRange = ts >= +this.sizeRange1CuttofTimestampUpper
            ? this.SIZE_RANGE_2
            : this.SIZE_RANGE_1;

        const gameSizeIndex = Math.floor(nextRandom() * sizeRange.length);
        const gameSize = sizeRange[gameSizeIndex];

        const gameDifficultyIndex = Math.floor(nextRandom() * this.getDifficultyRange(ts).length);
        const difficulty = this.getDifficultyRange(ts)[gameDifficultyIndex];

        // check with easter eggs / fun days
        // easter egg, Friday the 13th
        const timeZone = 'America/New_York'; // Timezone for New York
        const now = new Date(ts);

        // Convert current time to the New York timezone
        const localDateString = now.toLocaleString('en-US', { timeZone });
        const date = new Date(localDateString);
        if (date.getDate() === 13 && date.getDay() === 5) {
            return {
                gameSize: 13,
                difficulty: HexakaiGameDifficulty.ultraDifficult
            }
        }

        // pi day, board of size 14
        if (date.getDate() === 14 && date.getMonth() === 2) {
            return {
                gameSize: 14,
                difficulty: HexakaiGameDifficulties[
                    Math.floor(nextRandom() * HexakaiGameDifficulties.length)
                ]
            };
        }

        // april fools, easy board
        if (date.getDate() === 1 && date.getMonth() === 3) {
            return {
                gameSize: 1,
                difficulty: HexakaiGameDifficulty.ultraDifficult
            };
        }

        // leap day, board of size 16 at ultra difficult
        if (date.getDate() === 29 && date.getMonth() === 1) {
            return {
                gameSize: 16,
                difficulty: HexakaiGameDifficulty.ultraDifficult
            };
        }

        // august 15, "relaxation day"
        if (date.getDate() === 15 && date.getMonth() === 7) {
            return {
                gameSize: 15,
                difficulty: difficulty
            };
        }

        // if day=month (not 3) return day
        if (date.getDate() === date.getMonth() + 1 && date.getDate() > 3 && HexakaiBoardData.GAME_SIZES.includes(date.getDate())) {
            return {
                gameSize: date.getDate(),
                difficulty: difficulty
            };
        }

        // 16th of each month, get a board of size 16
        if (date.getDate() === 16) {
            return {
                gameSize: 16,
                difficulty: HexakaiGameDifficulties[
                    Math.floor(nextRandom() * HexakaiGameDifficulties.length)
                ]
            };
        }

        // default, return random params
        return {
            gameSize,
            difficulty
        }
    }

    static getStartOfDayTimestamp(ts?: number | string): number {
        if (typeof ts === 'string') {
            const [year, month, day] = ts.split("-");

            const timezoneSpec = this.isYmdInDaylightSavings(parseInt(year), parseInt(month), parseInt(day))
                ? `GMT-0400`
                : `GMT-0500`;

            const date = new Date(`${year} ${month} ${day} 00:00:00 ${timezoneSpec}`);
            return +date;
        }

        if (!ts) {
            ts = +new Date();
        }

        // Convert the timestamp to the local EST/EDT time based on the provided timestamp
        const localDate = new Date(
            new Date(ts).toLocaleString("en-US", { timeZone: "America/New_York" })
        );

        // Create a new Date object for midnight (00:00) of the same day in EST/EDT
        const midnightDate = new Date(
            localDate.getFullYear(),
            localDate.getMonth(),
            localDate.getDate()
        );

        // Convert midnight back to the original timestamp (UTC-based time)
        return +midnightDate;
    }

    static getDateString(ts?: number): string {
        const date = ts ? new Date(ts) : new Date();

        const estDateString = date.toLocaleString("en-US", {
            timeZone: "America/New_York",
        });
        const [month, day, year] = estDateString.split(/\/|,|:/).map(Number);

        return `${year}-${month}-${day}`;
    }

    static getPuzzleTimestamps(topTimestamp: number, count: number): {
        timestamps: {
            ts: number;
            no: number;
        }[];
        isEnd: boolean;
    } {
        if (topTimestamp >= +new Date()) {
            topTimestamp = +new Date();
        }

        const timestamps: { ts: number; no: number }[] = [];
        const firstT = this.getStartOfDayTimestamp(topTimestamp);

        timestamps.push({
            ts: firstT,
            no: this.getNumDaysDifference(firstT, this.FIRST_TIMESTAMP) + 1
        });

        for (let i = 1; i < count; i++) {
            const nextTimestamp = timestamps[timestamps.length - 1].ts - this.DAY_DURATION;
            if (nextTimestamp < this.FIRST_TIMESTAMP) {
                break;
            }

            timestamps.push({
                ts: nextTimestamp,
                no: this.getNumDaysDifference(nextTimestamp, this.FIRST_TIMESTAMP) + 1
            });
        }

        return {
            timestamps,
            isEnd: timestamps.length < count || timestamps[timestamps.length - 1].ts === this.FIRST_TIMESTAMP
        }
    }

    private static getNumDaysDifference(a: Date | number, b: Date | number) {
        const aDayStart = DailyPuzzleService.getStartOfDayTimestamp(+a);
        const bDayStart = DailyPuzzleService.getStartOfDayTimestamp(+b);

        // Convert inputs to Date objects
        const dateA = new Date(aDayStart);
        const dateB = new Date(bDayStart);

        // Set both dates to UTC midnight to avoid timezone discrepancies
        dateA.setUTCHours(0, 0, 0, 0);
        dateB.setUTCHours(0, 0, 0, 0);

        // Calculate the difference in time and convert it to days, truncating any fraction
        const msPerDay = 24 * 60 * 60 * 1000;
        const diffInMs = dateA.getTime() - dateB.getTime();

        // Directly dividing by msPerDay will now yield an integer due to UTC midnight alignment
        return diffInMs / msPerDay;
    }

    private static isTimestampInDaylightSavings(timestamp: number): boolean {
        // Create two Date objects for the same timestamp, one in UTC and one in EST/EDT
        const dateUTC = new Date(timestamp); // UTC version
        const dateEST = new Date(
            dateUTC.toLocaleString("en-US", { timeZone: "America/New_York" })
        );

        // Get the timezone offsets in minutes
        const utcOffset = dateEST.getTimezoneOffset();
        // 240 is the offset at daylight savings
        return utcOffset === 240;
    }

    private static isYmdInDaylightSavings(year: number, month: number, day: number): boolean {
        // Create a Date object for the given year, month (0-indexed), and day
        const date = new Date(Date.UTC(year, month - 1, day, 12)); // Use noon to avoid DST boundary issues

        // Convert the date to EST/EDT timezone
        const estDate = new Date(
            date.toLocaleString("en-US", { timeZone: "America/New_York" })
        );

        // Get the timezone offsets in minutes
        const utcOffset = estDate.getTimezoneOffset();
        // 240 is the offset at daylight savings
        return utcOffset === 240;
    };
}