import { HexakaiBoardState } from "../models/hexakai-board-state";
import { HexakaiBoardData } from "./hexakai-board-data";

/**
 * Visitor that allows traversing rows and multiple columns
 * Encapsulates column traversal calculations
 */
export class HexakaiBoardStateVisitor<T> {

    private boardData: HexakaiBoardData;
    private numRows: number;
    private largestRowIndex: number;

    private leftColForwardCache: [T, number, number][][][] = [];
    private leftColCache: [T, number, number][][][] = [];
    private rightColForwardCache: [T, number, number][][][] = [];
    private rightColCache: [T, number, number][][][] = [];

    // record a cache of how many cells each cell imposes a constraint on 
    private numberCellConstrains: number[][] = [];

    constructor(
        private board: HexakaiBoardState<T>
    ) {
        this.boardData = new HexakaiBoardData(board.gameSize);
        this.numRows = this.boardData.getNumRows();
        this.largestRowIndex = this.boardData.getLargestRowIndex();

        // iterate over the board once to compute paths
        // first, compute forward paths
        for (let row = 0; row < this.numRows; row++) {
            const colwidth = this.boardData.getNumCols(row);
            // caches
            this.leftColForwardCache.push([]);
            this.rightColForwardCache.push([]);
            // still push non-forward caches to help later on
            this.leftColCache.push([]);
            this.rightColCache.push([]);

            for (let col = 0; col < colwidth; col++) {
                // col left cache
                this.leftColForwardCache[row].push([]);
                this.leftColCache[row].push([]);
                this.computeColLeftForward(row, col, (cell, cellRow, cellCol) => {
                    this.leftColForwardCache[row][col].push(
                        [cell, cellRow, cellCol]
                    );
                });

                // col right cache
                this.rightColForwardCache[row].push([]);
                this.rightColCache[row].push([]);
                this.computeColRightForward(row, col, (cell, cellRow, cellCol) => {
                    this.rightColForwardCache[row][col].push(
                        [cell, cellRow, cellCol]
                    );
                });
            }
        }

        // now, compute complete paths and associate all cols and rows with them
        for (let row = 0; row <= this.largestRowIndex; row++) {

            // compute left col
            const colSize = this.boardData.getNumCols(row);
            const startCol = colSize - 1;
            this.computeColLeftForward(row, startCol, (cell, cellRow, cellCol) => {
                this.leftColCache[row][startCol].push(
                    [cell, cellRow, cellCol]
                );
                // if different than start, update to point to same list
                if (cellRow !== row) {
                    this.leftColCache[cellRow][cellCol] = this.leftColCache[row][startCol];
                }
            });
            // for each row under,

            this.computeColRightForward(row, 0, (cell, cellRow, cellCol) => {
                this.rightColCache[row][0].push(
                    [cell, cellRow, cellCol]
                );
                // if different than start, update to point to same list
                if (cellRow !== row) {
                    this.rightColCache[cellRow][cellCol] = this.rightColCache[row][0];
                }
            });
        }

        this.calculateCellContraintCounts();
    }

    public getBoard(): HexakaiBoardState<T> {
        return this.board;
    }

    public getGameSize(): number {
        return this.board.gameSize;
    }

    public getNumCells(): number {
        return this.board.gameSize*this.board.gameSize;
    }

    public get(row: number, col: number): T {
        return this.board.cells[row][col];
    }

    public getNumberConstraining(row: number, col: number): number {
        return this.numberCellConstrains[row][col];
    }

    /**
     * Visit a row starting from the start col
     */
    public *visitRowForward(
        row: number,
        startCol: number,
        skipFirst: boolean
    ): IterableIterator<[T, number, number]> {
        const colSize = this.boardData.getNumCols(row);

        if (skipFirst) {
            startCol++;
        }

        for (let col = startCol; col < colSize; col++) {
            yield [this.board.cells[row][col], row, col]
        }
    }

    public *visitColLeft(
        row: number,
        col: number
    ): IterableIterator<[T, number, number]> {
        const cellList = this.leftColCache[row][col];
        for (let i = 0; i < cellList.length; i++) {
            yield cellList[i];
        }
    }

    /**
     * Visit the column down and to the left from a start row
     */
    public *visitColLeftForward(
        startRow: number,
        col: number,
        skipFirst: boolean
    ): IterableIterator<[T, number, number]> {
        const cellList = this.leftColForwardCache[startRow][col];
        for (let i = skipFirst ? 1 : 0; i < cellList.length; i++) {
            yield cellList[i];
        }
    }

    public *visitColRight(
        row: number,
        col: number
    ): IterableIterator<[T, number, number]> {
        const cellList = this.rightColCache[row][col];
        for (let i = 0; i < cellList.length; i++) {
            yield cellList[i];
        }
    }

    /**
     * Visit the column down and to the right from a start row
     */
    public *visitColRightForward(
        startRow: number,
        col: number,
        skipFirst: boolean
    ): IterableIterator<[T, number, number]> {
        const cellList = this.rightColForwardCache[startRow][col];
        for (let i = skipFirst ? 1 : 0; i < cellList.length; i++) {
            yield cellList[i];
        }
    }

    public visitCol(
        row: number,
        col: number,
        type: number, //0 = left, 1 = right
    ): IterableIterator<[T, number, number]> {
        if (type === 0) {
            return this.visitColLeft(row, col);
        } else {
            return this.visitColRight(row, col);
        }
    }

    /**
     * Pre-compute col left paths
     */
    private computeColLeftForward(
        startRow: number,
        col: number,
        callback: (cell: T, row: number, col: number) => any
    ): void {
        let initialRow = startRow;

        for (let row = initialRow; row < this.boardData.getNumRows(); row++) {
            let thisCol = col;
            if (row > this.boardData.getLargestRowIndex()) {
                thisCol -= row - Math.max(startRow, this.boardData.getLargestRowIndex());
            }

            if (thisCol < 0 || thisCol >= this.boardData.getNumCols(row)) {
                break;
            }

            callback(this.board.cells[row][thisCol], row, thisCol);
        }
    }

    /**
     * Pre-compute col right paths
     */
    private computeColRightForward(
        startRow: number,
        col: number,
        callback: (cell: T, row: number, col: number) => any
    ): void {
        let colIncrement = startRow <= this.largestRowIndex
            ? -1
            : 0;

        for (let row = startRow; row < this.numRows; row++) {
            const rowSize = this.boardData.getNumCols(row);

            // if on top bottom half, col increments each additional row
            if (row <= this.largestRowIndex) {
                colIncrement++;
            }
            const thisCol = col + colIncrement;

            if (thisCol >= rowSize || thisCol < 0) {
                break;
            }

            callback(this.board.cells[row][thisCol], row, thisCol);
        }
    }

    countNumEmptyCellsConstraining(row: number, col: number): number {
        let count = 0;
        for (const [cell, cellRow, cellCol] of this.visitRowForward(row, col, false)) {
            if (!cell && (row !== cellRow || col !== cellCol)) {
                count++;
            }
        }
        for (const [cell, cellRow, cellCol] of this.visitColLeft(row, col)) {
            if (!cell && (row !== cellRow || col !== cellCol)) {
                count++;
            }
        }
        for (const [cell, cellRow, cellCol] of this.visitColRight(row, col)) {
            if (!cell && (row !== cellRow || col !== cellCol)) {
                count++;
            }
        }
        return count;
    }

    /**
     * For each cell, calculate how many other cells it constrains
     * TODO: this should be a simple calculation (r*2(n-1))?
     */
    private calculateCellContraintCounts(): void {
        for (let row = 0; row < this.numRows; row++) {
            this.numberCellConstrains.push([]);
            const colSize = this.boardData.getNumCols(row);
            for (let col = 0; col < colSize; col++) {
                // visit rows and columns, count how many cells are constrained
                let count = 0;
                for (const [cell, cellRow, cellCol] of this.visitRowForward(row, col, false)) {
                    if (row !== cellRow || col !== cellCol) {
                        count++;
                    }
                }
                for (const [cell, cellRow, cellCol] of this.visitColLeft(row, col)) {
                    if (row !== cellRow || col !== cellCol) {
                        count++;
                    }
                }
                for (const [cell, cellRow, cellCol] of this.visitColRight(row, col)) {
                    if (row !== cellRow || col !== cellCol) {
                        count++;
                    }
                }

                this.numberCellConstrains[row].push(count);
            }
        }
    }
}