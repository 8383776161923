export enum HexakaiPlusFeatures {
    noAds,
    advancedBoardGeneration, // pattern based generation
    hexCellInputType, // standard, numbers only, etc
    allDailyPuzzles, // if not enabled, only today's puzzle and puzzles that appear on YouTube will be available
};

export const HexakaiPlusFeatureDescriptions:{
    [key in HexakaiPlusFeatures]: { title: string; description: string };
} = {
    [HexakaiPlusFeatures.noAds]: {
        title: "Remove All Ads",
        description: "Enjoy Hexakai with an ad-free experience!"
    },
    [HexakaiPlusFeatures.allDailyPuzzles]: {
        title: "Unlock All Daily Puzzles",
        description: "The free version of Hexakai only includes today's daily puzzle and puzzles that appear on YouTube. With Hexakai Plus, you can access all daily puzzles."
    },
    [HexakaiPlusFeatures.hexCellInputType]: {
        title: "Tailor Your Gameplay Experience with Advanced Settings",
        description: "Unlock all gameplay settings, including the ability to change the way hexagons values appear on the board, to fine-tune your playing experience."
    },
    [HexakaiPlusFeatures.advancedBoardGeneration]: {
        title: "Unlock Advanced Board Generation Features",
        description: "Customize your playing experience with board generation options such as diagonal strings, top heavy, and more."
    }
}