import { ClientService } from "../client-service/client-service";
import BUILD from "../client-service/b.json";

export class GlobalAccessService {
    private static instance: GlobalAccessService;

    // hacky way of instantiating (if client service even needs this)
    static getInstance(): GlobalAccessService {
        if (!this.instance) {
            this.instance = new GlobalAccessService();
        }

        return this.instance;
    }

    private obj: any = {
        iteration: BUILD.id
    };
    private defaultObj: any = {
        iteration: BUILD.id
    };

    constructor() {
        if (GlobalAccessService.instance) {
            return GlobalAccessService.instance;
        }

        GlobalAccessService.instance = this;
    }

    registerGlobalHandlers(partial: any): void {
        this.register(this.obj, partial);
    }

    registerClientService(clientService: ClientService): void {
        if (clientService.getConfig().controls.window) {
            (global as any).hexakai = this.obj;
        } else {
            (global as any).hexakai = this.defaultObj;
        }
    }

    private register(root: any, partial: any): void {
        for (const [key, value] of Object.entries(partial)) {
            if (typeof value === 'function' || this.isClass(value)) {
                root[key] = value;
            } else {
                root[key] = {};
                this.register(root[key], value);
            }
        }
    }

    private isClass(obj: any): boolean {
        const isCtorClass = obj.constructor
            && obj.constructor.toString().substring(0, 5) === 'class'
        if (obj.prototype === undefined) {
            return isCtorClass
        }
        const isPrototypeCtorClass = obj.prototype.constructor
            && obj.prototype.constructor.toString
            && obj.prototype.constructor.toString().substring(0, 5) === 'class'
        return isCtorClass || isPrototypeCtorClass
    }

}