import { LitElement, html, css } from 'lit';
import { ClientService } from "../client-service/client-service";
import { LocalStorageService } from "../local-storage/local-storage-service";
import { ThemeService } from "../theme/theme-service";
import { WindowFullscreenService } from "../window-fullscreen-service/window-fullscreen-service";
import { SettingsService } from "../settings-service/settings-service";
import { CellValueType, CellValueTypes, Settings } from "../models/settings";
import { ModalService } from "../modal-service/modal-service";
import { Themes } from "../theme/themes";
import { customElement } from 'lit/decorators';
import { ClientDispatcherService } from '../client-dispatcher-service/client-dispatcher-service';
import { UserAuthenticationService } from '../user-authentication/user-authentication-service';
import { HexakaiPlusSubscriptionService } from '../hexakai-plus-subscription/hexakai-plus-subscription-service';
import { HexakaiPlusFeatures } from '../hexakai-plus-subscription/hexakai-plus-features';
import { ComponentDependencyTree } from '../component-dependency-tree/component-dependency-tree';

@customElement('settings-panel')
export class SettingsPanel extends LitElement {

    private clientService = ComponentDependencyTree.clientService;
    private modalService = ComponentDependencyTree.modalService;
    private themeService = ComponentDependencyTree.themeService;

    // TODO: update this to work properly
    private fullscreenService = new WindowFullscreenService(
        new ClientDispatcherService(this.clientService)
    );
    private settingsService = ComponentDependencyTree.settingsService;
    private hexakaiPlusService = ComponentDependencyTree.hexakaiPlusSubscriptionService;

    private currentTheme!: Themes;
    private soundsEnabled = false;
    private hexMenuUnified = false;
    private currentCellValueType!: CellValueType;
    private hexCellSettingEnabled = false;

    static styles = css`
        .setting-selected {
            --button-background-color: var(--button-hover-background-color);
        }
        .buttons {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 0.5em;
        }
        .setting-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: left;
            justify-content: flex-start;
            width: 95%;
            margin-bottom: 15px;
        }
        .setting {
            font-weight: bold;
            font-size: 1.1em;
            min-width: 200px;
            margin-right: 10px;
        }
        #all-settings {
            overflow: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .info-btn {
            position: relative;
            cursor: pointer;
            vertical-align: text-top;
            margin-left: 0.2em;
        }

        hr {
            width: 98%;
        }

        @media screen and (max-width: 1400px) {
            .setting {
                min-width: initial;
            }
            .setting-container {
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
            .buttons {
                justify-content: center;
            }
        }
    `;

    connectedCallback() {
        super.connectedCallback();
        this.initializeSettings();
    }

    async initializeSettings() {
        // TODO: make concurrent
        const settings = await this.settingsService.getSettings();
        this.currentTheme = await this.themeService.getCurrentTheme();
        this.hexCellSettingEnabled = await this.hexakaiPlusService.isFeatureUnlocked(HexakaiPlusFeatures.hexCellInputType);

        this.soundsEnabled = settings.boardSoundsEnabled;
        this.hexMenuUnified = settings.unifiedCellMenuEnabled;
        this.currentCellValueType = settings.cellValueType;
        this.requestUpdate();
    }

    render() {
        return html`
            <div id="all-settings">
                ${this.renderThemeSettings()}
                <hr/>
                ${this.renderSoundSettings()}
                <hr/>
                ${this.clientService.getConfig().body.fullScreenEnabled 
                    ? [this.renderFullscreenView(), html`<hr/>`]
                    : ""}
                ${this.renderCellValueTypeSettings()}
                <hr/>
                ${this.renderHexMenuSettings()}
            </div>
        `;
    }

    renderThemeSettings() {
        return html`
            <div class="setting-container">
                <p class="setting">Theme: <img class="info-btn" src="/info.svg" @click="${() => this.showInfoText("theme")}"></p>
                <div class="buttons">
                    ${this.themeService.getThemes().map(theme => html`
                        <styled-button
                            class="theme-settings-button ${this.currentTheme === theme ? 'setting-selected' : ''}"
                            @click="${(e: Event) => this.setTheme(e, theme as any)}"
                        >${theme.substring(0, 1).toUpperCase() + theme.substring(1)}</styled-button>`)}
                </div>
            </div>`;
    }

    renderSoundSettings() {
        return html`
            <div class="setting-container">
                <p class="setting">Sounds: <img class="info-btn" src="/info.svg" @click="${() => this.showInfoText("boardSoundsEnabled")}"></p>
                <div class="buttons">
                    <styled-button class="sound-enabled-disabled-button ${this.soundsEnabled ? 'setting-selected' : ''}" @click="${(e: Event) => this.setSoundIsEnabled(e, true)}">Enabled</styled-button>
                    <styled-button class="sound-enabled-disabled-button ${!this.soundsEnabled ? 'setting-selected' : ''}" @click="${(e: Event) => this.setSoundIsEnabled(e, false)}">Disabled</styled-button>
                </div>
            </div>`;
    }

    renderFullscreenView() {
        let isFullscreenSetting = false;
        return html`
            <div class="setting-container">
                <p class="setting">Display Mode: <img class="info-btn" src="/info.svg" @click="${() => this.showInfoText("fullscreen")}"></p>
                <div class="buttons">
                    <styled-button class="fullscreen-button ${!isFullscreenSetting ? 'setting-selected' : ''}" @click="${(e: Event) => this.setFullscreen(e, false)}">Default</styled-button>
                    <styled-button class="fullscreen-button ${isFullscreenSetting ? 'setting-selected' : ''}" @click="${(e: Event) => this.setFullscreen(e, true)}">Full-Screen</styled-button>
                </div>
            </div>`;
    }

    renderCellValueTypeSettings() {
        

        return html`
            <div class="setting-container">
                <p class="setting">Hex Cells:
                    <img class="info-btn" src="/info.svg" @click="${() => this.showInfoText("cellValueType")}" />
                    ${this.hexCellSettingEnabled ? "" : html`<img class="info-btn" src="/lock.svg" @click="${() => this.showInfoText("cellValueType")}" />`}
                </p>
                <div class="buttons">
                    ${CellValueTypes.map(type => html`
                        <styled-button
                            class="cell-value-type-settings-button ${this.currentCellValueType === type ? 'setting-selected' : ''}"
                            .style="${this.hexCellSettingEnabled ? "" : "filter: blur(1px)"}"
                            @click="${(e: Event) => this.hexCellSettingEnabled ? this.setValueType(e, type) : this.showInfoText("cellValueType")}"
                        >${type}</styled-button>`)}
                </div>
            </div>`;
    }

    renderHexMenuSettings() {
        return html`
            <div class="setting-container">
                <p class="setting">Hex Menu: <img class="info-btn" src="/info.svg" @click="${() => this.showInfoText("unifiedCellMenuEnabled")}"></p>
                <div class="buttons">
                    <styled-button class="hex-menu-button ${!this.hexMenuUnified ? 'setting-selected' : ''}" @click="${(e: Event) => this.setHexMenuUnified(e, false)}">Separate</styled-button>
                    <styled-button class="hex-menu-button ${this.hexMenuUnified ? 'setting-selected' : ''}" @click="${(e: Event) => this.setHexMenuUnified(e, true)}">Unified</styled-button>
                </div>
            </div>`;
    }

    setTheme(e: Event, theme: Themes) {
        this.themeService.setTheme(theme);
        this.currentTheme = theme;
        this.updateSelectedButton(e, 'theme-settings-button');
    }

    setSoundIsEnabled(e: Event, isEnabled: boolean) {
        this.settingsService.updateSettings({ boardSoundsEnabled: isEnabled });
        this.soundsEnabled = isEnabled;
        this.updateSelectedButton(e, 'sound-enabled-disabled-button');
    }

    setValueType(e: Event, type: CellValueType) {
        this.settingsService.updateSettings({ cellValueType: type });
        this.currentCellValueType = type;
        this.updateSelectedButton(e, 'cell-value-type-settings-button');
    }

    setHexMenuUnified(e: Event, isEnabled: boolean) {
        this.settingsService.updateSettings({ unifiedCellMenuEnabled: isEnabled });
        this.hexMenuUnified = isEnabled;
        this.updateSelectedButton(e, 'hex-menu-button');
    }

    async setFullscreen(e: Event, isFullscreen: boolean) {
        const isCurrentlyFullscreen = await this.fullscreenService.isFullscreen();
        if (isCurrentlyFullscreen !== isFullscreen) {
            await this.fullscreenService.setFullscreen(isFullscreen);
            this.updateSelectedButton(e, 'fullscreen-button');
        }
    }

    showInfoText(type: keyof Settings | 'theme' | 'fullscreen') {
        let title = '';
        let text: any = '';

        switch (type) {
            case 'theme':
                title = "Change Theme";
                text = "Choose from light, dark, and other color themes.";
                break;
            case 'fullscreen':
                title = "Display Mode";
                text = "Toggle between default and fullscreen display modes.";
                break;
            case 'boardSoundsEnabled':
                title = "Board Sounds";
                text = "Toggle sound effects on or off.";
                break;
            case 'cellValueType':
                const plusUrl = this.clientService.createLocalLink("plus", {}, true);

                title = "Hex Cell Display Type";
                text = html`<p>Choose how to display values in hex cells.</p>
                    ${this.hexCellSettingEnabled ? html``: html`<hr style="width: 95%"/>
                    <p>
                        <b>Note:</b> this feature is only available with a <a href="${plusUrl}"><i>Hexakai Plus</i></a> subscription.
                    </p>`}
                `;
                break;
            case 'unifiedCellMenuEnabled':
                title = "Hex Input Menu Type";
                text = "Choose between separate and unified menu types.";
                break;
        }

        this.modalService.showModal('info-text', title, [text, html`<br><br>`]);
    }

    updateSelectedButton(e: Event, buttonClass: string) {
        const buttons = this.shadowRoot!.querySelectorAll(`.${buttonClass}`);
        buttons.forEach(button => button.classList.remove('setting-selected'));
        (e.target as HTMLElement).classList.add('setting-selected');
    }
}
