import { ClientService } from "../client-service/client-service";
import { StorageService } from "../models/storage-service";
import { PromiseSubject } from "../promise-subject/promise-subject";
import { IUserInfo, IUserSession } from "../models/user-info";
import { v5 as uuidv5 } from 'uuid';

/**
 * This service uses Google Authentication for user logins.
 */

interface GoogleCredentialResponse {
    credential: string;
    select_by: string;
}

interface GoogleIdTokenPayload {
    iss: string;             // Issuer (e.g., "https://accounts.google.com")
    azp: string;             // Authorized party (your client ID)
    aud: string;             // Audience (your client ID)
    sub: string;             // Subject (user's unique ID)
    email: string;           // User's email address
    email_verified: boolean; // Whether the email is verified
    name: string;            // Full name
    picture: string;         // URL of the user's profile picture
    given_name: string;      // First name
    family_name: string;     // Last name
    iat: number;             // Issued at (timestamp)
    exp: number;             // Expiration time (timestamp)
    jti: string;             // Token ID
}

export class UserAuthenticationService {

    private static instance: UserAuthenticationService;
    private static NAMESPACE = 'd15a4a20-74f1-11ed-a1eb-0242ac120002';


    public static GOOGLE_CLIENT_ID = "880571782913-jjih19jn18ctchrs33dotjlq6t96n1ob.apps.googleusercontent.com";

    private USER_SESSION_KEY = "google-session";

    private userProfilePromise = new PromiseSubject<IUserSession | null>();

    constructor(
        private clientService: ClientService,
        private storageService: StorageService
    ) {
        if (UserAuthenticationService.instance) {
            return UserAuthenticationService.instance;
        }

        UserAuthenticationService.instance = this;

        this.init();
    }

    getUserInfo(): Promise<IUserInfo | null> {
        return this.userProfilePromise.getPromise();
    }

    isLoggedIn(): Promise<boolean> {
        return this.getUserInfo().then(user => !!user);
    }

    async logOut(): Promise<void> {
        await this.storageService.remove(this.USER_SESSION_KEY);

        // reload this page
        // TODO: this is hacky
        window.location.reload();
    }

    private async init(): Promise<void> {
        if (!this.clientService.getConfig().userProfile.enabled) {
            this.userProfilePromise.resolve(null);
            return;
        }

        // register login callback
        (window as any).googleLoginCredentialCallback = this.onCredentialsReceived.bind(this);

        this.loadUserSession();
    }

    private async loadUserSession() {
        // load user from storage
        const saved = await this.storageService.get<GoogleIdTokenPayload>(this.USER_SESSION_KEY);
        if (!saved) {
            this.userProfilePromise.resolve(null);
        } else if (saved.exp >= Date.now()) {
            this.storageService.remove(this.USER_SESSION_KEY);
            this.userProfilePromise.resolve(null);
        } else {
            this.userProfilePromise.resolve(this.deriveSession(saved));
        }
    }

    private async onCredentialsReceived(response: GoogleCredentialResponse) {
        console.log(`[UserCredentialsService] credentials received`, response);

        const idToken = response.credential;

        // Decode the JWT token
        const payload = this.parseJwt(idToken);

        await this.storageService.set<GoogleIdTokenPayload>(this.USER_SESSION_KEY, payload);

        // reload this page
        // TODO: this is hacky
        window.location.reload();
    }

    private parseJwt(token: string): GoogleIdTokenPayload {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                .join('')
        );
        return JSON.parse(jsonPayload);
    }

    private deriveSession(payload: GoogleIdTokenPayload): IUserSession {
        return {
            hexakaiUserId: this.deriveId(payload.email),
            fullName: payload.name,
            firstName: payload.name.split(" ")[0],
            lastName: payload.name.split(" ").reverse()[0],
            email: payload.email,
            pictureUrl: payload.picture,
            sessionExpirationTimestamp: payload.exp
        };
    }

    // NOTE: this CANNOT change without an ID migration!
    private deriveId(email: string): string {
        return uuidv5(email, UserAuthenticationService.NAMESPACE);
    }
}