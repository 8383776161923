import { ClientService } from "../client-service/client-service";
import { PromiseSubject } from "../promise-subject/promise-subject";
import { UserAuthenticationService } from "../user-authentication/user-authentication-service";
import { HexakaiPlusFeatures } from "./hexakai-plus-features";
import * as RevenueCat from "@revenuecat/purchases-js";

export class HexakaiPlusSubscriptionService {

    private static instance: HexakaiPlusSubscriptionService;

    private _isSubscribed = new PromiseSubject<boolean>();

    constructor(
        private clientService: ClientService,
        private userAuthenticationService: UserAuthenticationService
    ) {
        if (HexakaiPlusSubscriptionService.instance) {
            return HexakaiPlusSubscriptionService.instance;
        }

        HexakaiPlusSubscriptionService.instance = this;
        this.init();
    }

    /**
     * For now, assume all features are unlocked if the user is subscribed to Hexakai Plus
     */
    async isFeatureUnlocked(feature: HexakaiPlusFeatures): Promise<boolean> {
        if (!this.clientService.getConfig().hexakaiPlus.enabled) {
            return feature !== HexakaiPlusFeatures.noAds;
        }

        return this._isSubscribed.getPromise();
    }

    isSubscribed(): Promise<boolean> {
        return this._isSubscribed.getPromise();
    }

    getPaywallUrl(): Promise<string> {
        return this.userAuthenticationService.getUserInfo().then(userInfo => {
            if (!userInfo) {
                throw new Error(`Cannot generate a url if the user is not logged in.`)
            }

            return `${this.clientService.getConfig().hexakaiPlus.subscriptionUrlPrefix}${userInfo.hexakaiUserId}`;
        });
    }

    private async init() {
        // load current user
        const currentUser = await this.userAuthenticationService.getUserInfo();
        if (!currentUser) {
            this._isSubscribed.resolve(false);
            return;
        }

        // check if subscribed
        const isSubscribed = await this.isEmailSubscribed(currentUser.hexakaiUserId);
        this._isSubscribed.resolve(isSubscribed);
    }

    private async isEmailSubscribed(userId: string): Promise<boolean> {
        const publicApiKey = this.clientService.getConfig().hexakaiPlus.publicApiKey;
        RevenueCat.Purchases.configure(publicApiKey, userId);
        const customerInfo = await RevenueCat.Purchases.getSharedInstance().getCustomerInfo();
        const entitlements = customerInfo.entitlements.active;
        console.debug(`[HexakaiPlusSubscriptionService] entitlements`, entitlements);
        
        const hexakaiPlusEntitlement = entitlements["Hexakai Plus"];
        if (!hexakaiPlusEntitlement) {
            return false;
        }

        return hexakaiPlusEntitlement.isActive;
    }
}