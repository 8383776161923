import { RngParams } from "../../models/rng-params";
import { BaseRng } from "../rng";
import { Random, MersenneTwister19937 } from "random-js";

export class MersenneTwisterRng extends BaseRng {

    private twister: Random;

    constructor(params: RngParams) {
        super(params);
        let seed = params.seed;
        if (typeof seed !== 'undefined') {
            seed = typeof params.seed === 'string'
                ? (this.stringToNumber(params.seed) * 1234567890123) % 2**32
                : params.seed;
        } else {
            seed = Math.random();
        }

        if (Array.isArray(seed!)) {
            this.twister = new Random(MersenneTwister19937.seedWithArray(seed as number[]));
        } else {
            this.twister = new Random(MersenneTwister19937.seed(seed!));
        }
    }
    
    protected getNext(): number {
        return this.twister.real(0, 1, true);
    }

}