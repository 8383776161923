import { html } from "lit";
import { ClientService } from "../client-service/client-service";
import { ModalLayover } from "../components/modal-layover";
import { LocalStorageService } from "../local-storage/local-storage-service";
import { GameAchievementService } from "../achievements/game-achievement-service";
import { ClientDispatcherService } from "../client-dispatcher-service/client-dispatcher-service";
import { ToastService } from "../toast-service/toast-service";
import { ConsoleInterceptor } from "../console-logger/console-logger";
import { UserEventsServiceComposite } from "../user-events/user-events-service-composite";
import { tutorialRequestedEvent } from "../user-events/events/tutorial-requested-event";
import { UserAuthenticationService } from "../user-authentication/user-authentication-service";
import { userInfo } from "os";
import { ComponentDependencyTree } from "../component-dependency-tree/component-dependency-tree";

/** Handle themes and other concerns for the header element */
const clientService = ComponentDependencyTree.clientService;
const storageService = ComponentDependencyTree.localStorageService;
const userEventsService = ComponentDependencyTree.userEventsServiceComposite;
const userAuthenticationService = ComponentDependencyTree.userAuthenticationService;

document.addEventListener('DOMContentLoaded', () => {
    handleFooterLinks();
    handleSettingsClick();
    handleTutorial();
    updateUserProfile();
});

async function handleFooterLinks(): Promise<void> {
    const el = document.getElementById('sp-hp')! as HTMLAnchorElement;
    if (!el) {
        return;
    }

    if (clientService.getConfig().hexakaiPlus.enabled) {
        el.innerText = "Hexakai Plus";
        el.href = clientService.createLocalLink("plus", {}, true);
    } else {
        el.innerText = "Support the Project";
        el.href = clientService.createLocalLink("support-hexakai", {}, true);
    }
}

function handleSettingsClick(): void {
    const settingsEl = document.getElementById('settings-container')!;
    const nameEl = document.getElementById('game-name')!;
    let longPressTimeout: ReturnType<typeof setTimeout>;

    const startLongPress = () => {
        longPressTimeout = setTimeout(showModal, 5_000);
    };

    const cancelLongPress = () => {
        clearTimeout(longPressTimeout);
    };

    settingsEl.addEventListener("click", () => {
        console.log("[ListenThemeClick-script] mouse clickecd");
        navigateToSettings();
        event?.preventDefault();
    });

    // Mouse events
    nameEl.addEventListener("mousedown", startLongPress);
    nameEl.addEventListener("mouseup", cancelLongPress);
    nameEl.addEventListener("mouseleave", cancelLongPress);

    // Touch events
    nameEl.addEventListener("touchstart", startLongPress);
    nameEl.addEventListener("touchend", cancelLongPress);
    nameEl.addEventListener("touchcancel", cancelLongPress);
}

function handleTutorial(): void {
    // tutorial
    document.getElementById('tutorial-menu')!.addEventListener('click', async (e) => {

        await userEventsService.logEvent(
            tutorialRequestedEvent(void 0)
        );

        await storageService.set("tutorial-in-progress", {
            is: true
        });
        const url = clientService.createLocalLink(
            "app",
            {},
            true
        );
        window.location.href = url
    }, true);
}

async function updateUserProfile(): Promise<void> {
    const el = document.getElementById('user-profile-icon')! as HTMLImageElement;

    userAuthenticationService.getUserInfo().then(userInfo => {
        if (!userInfo) {
            return;
        }

        if (userInfo.pictureUrl) {
            el.src = userInfo.pictureUrl;
            el.classList.add("user-profile-custom");

            document.getElementById("settings-container")!.classList.add("custom");
        }
    });
}

async function showModal(): Promise<void> {
    const clientService = new ClientService();
    const achievementService = new GameAchievementService(
        new ClientService(),
        new LocalStorageService(),
        new ToastService(),
        new ClientDispatcherService(
            new ClientService()
        )
    );

    const contents = {
        config: clientService.getConfig(),
        achievements: await achievementService.getDebugInfo(),
        // TODO: this is a singleton; but hacky way to do it
        logs: new ConsoleInterceptor(console).getLogs()
    };

    const configJSON = JSON.stringify(contents, null, 4);
    const modal = new ModalLayover();
    modal.title = "Technical Specifications";
    modal.classList.add('left-content')
    modal.contentTemplate = html`
<pre style="width: 100%">
<code>
${configJSON}
</code>
</pre>
    `;
    modal.open = true;

    document.body.appendChild(modal);
}


/**
 * TODO: move this into its own component?
 */
async function navigateToSettings(): Promise<void> {
    const url = clientService.createLocalLink(
        "account",
        {},
        true
    );
    window.location.href = url;
}