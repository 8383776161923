import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { GameAchievement } from '../models/game-achievement';
import { GameAchievementService } from '../achievements/game-achievement-service';
import { ClientService } from '../client-service/client-service';
import { LocalStorageService } from '../local-storage/local-storage-service';
import { ToastService } from '../toast-service/toast-service';
import { ModalService } from '../modal-service/modal-service';
import { ClientDispatcherService } from '../client-dispatcher-service/client-dispatcher-service';
import { ComponentDependencyTree } from '../component-dependency-tree/component-dependency-tree';

interface AchievementDisplayData extends GameAchievement {
    style: string;
    unlocked: boolean;
};

@customElement('achievements-view')
export class AchievementsView extends LitElement {

    private achievementService = ComponentDependencyTree.achievementService;
    private modalService = ComponentDependencyTree.modalService;

    private isLoading = true;
    private achievementData: AchievementDisplayData[] = [];
    private numAchieved = 0;
    private achievementError: string | null = null;

    static styles = css`
        :host {
            display: block;
            text-align: var(--text-align, center);
        }

        #icons-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
        }

        .achievement {
            width: 70px;
            height: 70px;
            margin: 10px;
            cursor: pointer;
        }

        .achievement-img {
            width: 100%;
            height: 100%;
        }

        @media screen and (max-width: 1500px) {
            .achievement {
                width: 35px;
                height: 35px;
                margin: 7px;
            }
        }
    `;

    connectedCallback(): void {
        console.log("[AchievementsView] initializing.");

        super.connectedCallback();
        this.loadAchievements().catch(err => {
            console.log("[AchievementsView] error fetching achievements", err);
            this.achievementError = "We encountered an error while loading your achievements."
            this.isLoading = false;
            this.requestUpdate();
        });
    }

    render() {
        if (this.isLoading) {
            return html`
                <loading-display>
                </loading-display>
            `;
        }

        if (this.achievementError) {
            return html`
                <p>${this.achievementError}</p>
            `;
        }

        const achievementMessage = this.numAchieved === 0
            ? html`You haven't unlocked any achievements yet!`
            : html`You've unlocked ${this.numAchieved} out of ${this.achievementData.length} achievements!`

        return html`
            <div id="icons-container">
                ${this.achievementData.map(ach => html`
                    <div class="achievement" title="${ach.name}" @click="${() => this.renderModal(ach)}">
                        <img-smart style="${ach.style}" class="achievement-img" src="${ach.iconUrl}"></img-smart>
                    </div>
                `)}
            </div>

            <p>
                ${achievementMessage}
            </p>
        `;
    }

    private renderModal(achievement: AchievementDisplayData): void {
        this.modalService.showModal(
            "achievement-modal",
            achievement.unlocked
                ? "Unlocked Achievement"
                : "Locked Achievement",
            html`
                <style>
                    .container {
                        display: flex;
                        align-items: center;
                    }

                    .container img-smart {
                        width: 50px;
                        height: 50px;
                        margin-right: 15px;
                    }
                </style>
                <div class="container">
                    <img-smart style="${achievement.style}" src="${achievement.iconUrl}" ></img-smart>
                    <p>${achievement.name}</p>
                </div>
            `
        );
    }

    private async loadAchievements(): Promise<void> {
        const allAchievements = GameAchievementService.getAllAchievements();

        try {
            const playerAchievements = await this.achievementService.getAchievements();
            this.achievementData = allAchievements.map(achievement => ({
                ...achievement,
                style: playerAchievements.find(pa => pa.id === achievement.id)
                    ? ""
                    : "filter: grayscale(100%);",
                unlocked: !!playerAchievements.find(pa => pa.id === achievement.id)
            }));
    
            this.numAchieved = playerAchievements.length;
    
            console.log("[AchievementsView] achievements loaded", this.achievementData);
        } catch (err) {
            this.achievementError = `${err}`;
            console.log("[AchievementsView] error while loading achievements", err);
        }

        this.isLoading = false;
            this.requestUpdate();
    }
}