import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators';
import { ClientService } from '../client-service/client-service';

@customElement('styled-button')
export class StyledButton extends LitElement {

    private clientService = new ClientService();

    static styles = css`
        :host {
            display: inline-block;
            user-select: none;
            pointer-events: auto;
        }

        .button {
            font-family: var(--button-font);
            font-size: var(--font-size);
            font-weight: bold;

            background-color: var(--button-background-color);
            color: var(--color-override, var(--body-color));
            border: 1px solid var(--body-color);
            padding: var(--padding, 10px 20px);
            cursor: pointer;
            transition: background-color 0.3s;
            border-radius: var(--radius, 20px);
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .button-point {
            pointer-events: auto;
        }

        .button:hover {
            background-color: var(--button-hover-background-color);
        }
    `;

    @property() disabled = false;

    connectedCallback(): void {
        super.connectedCallback();

        if (this.isPointerUpCssForce()) {
            this.style.setProperty("pointer-events", "unset");

            this.addEventListener("touchend", () => {
                const button = this.shadowRoot!.querySelector("button")!;
                button.style.setProperty("pointer-events", "none");
                button.blur();
                this.blur();
                setTimeout(() => {
                    button.style.setProperty("pointer-events", "auto");
                }, 500);
            });
        }
    }

    render() {
        const classes = [
            "button",
            this.isPointerUpCssForce()
                ? " button-point"
                : ""
        ];

        return html`
            <button .disabled="${this.disabled}" class="${classes.join(" ")}">
                <slot></slot>
            </button>
        `;
    }

    private isPointerUpCssForce(): boolean {
        if (typeof this.clientService.getConfig().body.styledButton.pointerUpCssForce === 'boolean') {
            return this.clientService.getConfig().body.styledButton.pointerUpCssForce as boolean;
        }

        const isSafari = (
            'safari' in window ||
            (navigator.vendor && navigator.vendor.indexOf('Apple') > -1) ||
            (CSS.supports('-webkit-backdrop-filter', 'none') && 
             !CSS.supports('backdrop-filter', 'none'))
        );

        return !isSafari;
    }
}
