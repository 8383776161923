export enum RngType {
    default = "default",
    mkAlea = "mkAlea",
    logisticMap = "logisticMap",
    fractional = "fractional",
    crypto = "crypto",
    rootRatio = "rootRatio",
    circle = "circle",
    xorShift = "xorShift",
    collatz = "collatz",
    geometricRatio = "geometricRatio",
    mersenneTwister = "mersenneTwister"
}

export interface RngParams {
    rngtype?: RngType;
    seed?: string | number | (string | number)[];
    data?: any
}