import { ClientService } from "../client-service/client-service";
import { StorageMessageReceiverService } from "../local-storage/storage-message-receiver-service";
import { HexakaiGameBoardCreator, HexakaiGameCreatorResponse } from "../models/hexakai-game-board-creator";
import { HexakaiGameParams } from "../models/hexakai-game-params";
import { RngParams, RngType } from "../models/rng-params";
import { ShufflerParams, ShufflerType } from "../models/shuffler-params";
import { StorageService } from "../models/storage-service";
import { boardGeneratedEvent } from "../user-events/events/board-generated-event";
import { UserEventsServiceComposite } from "../user-events/user-events-service-composite";

export class HexakaiGameBoardCreatorWebWorker implements HexakaiGameBoardCreator {

    private worker!: Worker;
    private storageMessageReceiverService: StorageMessageReceiverService;

    constructor(
        private userEventsServiceComposite: UserEventsServiceComposite,
        private clientService: ClientService,
        storageService: StorageService,
    ) {
        this.worker = new Worker(new URL('../web-workers/hexakai-game-board-creator.web-worker.ts', import.meta.url), { type: 'module' });
        this.storageMessageReceiverService = new StorageMessageReceiverService(
            this.worker,
            storageService
        );
    }

    async create(
        params: HexakaiGameParams,
        valueRandomSeed?: number | string | RngParams,
        hintRandomSeed?: number | string | RngParams | ShufflerParams,
        algorithmVersion = -1,
        dailyPuzzleTimestamp = 0
    ): Promise<HexakaiGameCreatorResponse> {
        if (algorithmVersion === -1) {
            algorithmVersion = this.clientService.getConfig().gameBoard.defaultAlgorithm;
        }

        let valueRandomSeedType = this.getSeedType(valueRandomSeed);
        let hintRandomSeedType = this.getSeedType(hintRandomSeed);

        return new Promise((resolve, reject) => {
            const startTime = +new Date();
            // Setup message listener inside the promise
            this.worker.onmessage = (event: MessageEvent) => {
                if (event.data.type !== 'game-created') {
                    return;
                }
                console.log("[HexakaiGameBoardCreatorWebworker] Response received", event.data);
                const executionTime = +new Date() - startTime;
                if (event.data.error) {
                    this.userEventsServiceComposite.logEvent(
                        boardGeneratedEvent({
                            ...params,
                            executionTime,
                            isSuccess: true,
                            error: `${event.data.error}`,
                            hintRandomSeedType,
                            valueRandomSeedType,
                            dailyPuzzleTimestamp
                        })
                    );
                    reject(new Error(event.data.error));
                } else {
                    this.userEventsServiceComposite.logEvent(
                        boardGeneratedEvent({
                            ...params,
                            executionTime,
                            isSuccess: true,
                            error: ``,
                            hintRandomSeedType,
                            valueRandomSeedType,
                            dailyPuzzleTimestamp
                        })
                    );
                    resolve(event.data.boards as HexakaiGameCreatorResponse);
                }
            };

            // Error handling for worker errors
            this.worker.onerror = (event: ErrorEvent) => {
                console.error("[HexakaiGameBoardCreatorWebworker] Worker error:", event.message);
                reject(new Error(event.message));
            };

            // Send the game parameters to the worker
            this.worker.postMessage({
                ...params,
                type: 'game-create-request',
                valueRandomSeed,
                hintRandomSeed,
                algorithmVersion
            });
        });
    }

    private getSeedType(seed: undefined | number | string | RngParams | ShufflerParams): string {
        if (typeof seed === 'undefined' || seed === null) {
            return '';
        }

        if (typeof seed === 'number') {
            return 'number_seed';
        }

        if ((seed as RngParams).rngtype) {
            return (seed as RngParams).rngtype!;
        }

        return (seed as ShufflerParams).shufflerType!;
    }
}