import EventEmitter from "eventemitter3";
import { CellValueType, Settings } from "../models/settings";
import { StorageService } from "../models/storage-service";
import { HexakaiPlusSubscriptionService } from "../hexakai-plus-subscription/hexakai-plus-subscription-service";
import { ClientService } from "../client-service/client-service";
import { HexakaiPlusFeatures } from "../hexakai-plus-subscription/hexakai-plus-features";

export class SettingsService {

    private static instance: SettingsService;
    private SETTINGS_KEY = "user-settings";

    private eventEmitter = new EventEmitter();

    private settingsCache!: Settings;

    constructor(
        private storageService: StorageService,
        private hexakaiPlusService: HexakaiPlusSubscriptionService
    ) {
        if (SettingsService.instance) {
            return SettingsService.instance;
        }

        SettingsService.instance = this;
    }

    static getSettingsTexts(): { [key in keyof Settings]: string } {
        return {
            boardSoundsEnabled: "This setting allows you to enable or disable game sounds.",
            cellValueType: "This setting allows you to change how you display cell values. Options include standard, numbers only, letters only, greek alphabet, and even emojis.",
            unifiedCellMenuEnabled: "This setting changes the way you assign cell values, colors, and pencil markings. When unified menu is enabled, all controls appear simultaneously. When disabled, you'll need to right click or long press a cell to display the color and pencil marking options."
        };
    }

    async getSettings(): Promise<Settings> {
        if (!this.settingsCache) {
            // load from storage
            const settings = await this.loadFromStorage();
            await this.filterHexakaiPlus(settings);
            this.settingsCache = settings;
            console.log(`[SettingsService] loaded settings`, this.settingsCache);
        }

        // hacky workaround
        return JSON.parse(JSON.stringify(this.settingsCache));
    }

    /**
     * This loads from storage and applies default settings if any new have come in since last save
     */
    private loadFromStorage(): Promise<Settings> {
        return this.storageService
            .get<Settings>(this.SETTINGS_KEY)
            .then((settings) => settings || this.getDefaultSettings())
            .then((settings) => {
                // in case new settings were added, add the defaults in
                return {
                    ...this.getDefaultSettings(),
                    ...settings
                }
            });
    }

    /**
     * If the user isn't subscribed to Hexakai Plus, this ensures they don't have any plus settings applied
     */
    private async filterHexakaiPlus(settings: Settings): Promise<void> {
        const [
            hexCellInputTypeEnabled
        ] = await Promise.all([
            this.hexakaiPlusService.isFeatureUnlocked(HexakaiPlusFeatures.hexCellInputType)
        ]);

        if (!hexCellInputTypeEnabled) {
            settings.cellValueType = CellValueType.standard;;
        }
    }

    async updateSettings(newSettings: Partial<Settings>): Promise<void> {
        const existingSettings = await this.getSettings();
        const fullNewSettings = {
            ...existingSettings,
            ...newSettings
        };
        await this.storageService.set(this.SETTINGS_KEY, fullNewSettings);

        // hacky workaround
        this.settingsCache = JSON.parse(JSON.stringify(fullNewSettings));
        console.log(`[SettingsService] updated settings`, this.settingsCache);
        this.eventEmitter.emit('settings-updated', JSON.parse(JSON.stringify(fullNewSettings)));

    }

    onSettingsUpdated(callback: (settings: Settings) => void): void {
        this.eventEmitter.on('settings-updated', async () => {
            try {
                await callback(this.settingsCache);
            } catch (err) {
                console.warn(`[SettingsService] error caught on settings updated callback`, err);
            }
        });
    }

    private getDefaultSettings(): Settings {
        return <Settings>{
            boardSoundsEnabled: true,
            cellValueType: CellValueType.standard,
            unifiedCellMenuEnabled: false
        };
    }
}