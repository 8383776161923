import { GameAchievementService } from "../achievements/game-achievement-service";
import { ClientDispatcherService } from "../client-dispatcher-service/client-dispatcher-service";
import { ClientService } from "../client-service/client-service";
import { HexakaiPlusDailyPuzzleService } from "../daily-puzzle/daily-puzzle-hexakai-plus-service";
import { DailyPuzzleService } from "../daily-puzzle/daily-puzzle-service";
import { HexakaiGameBoardCreatorWebWorker } from "../hexakai-game/hexakai-game-board-creator-webworker";
import { HexakaiPlusSubscriptionService } from "../hexakai-plus-subscription/hexakai-plus-subscription-service";
import { LocalStorageService } from "../local-storage/local-storage-service";
import { ModalService } from "../modal-service/modal-service";
import { SessionService } from "../session-service/session-service";
import { SettingsService } from "../settings-service/settings-service";
import { ShareService } from "../share-service/share-service";
import { ThemeService } from "../theme/theme-service";
import { ToastService } from "../toast-service/toast-service";
import { UserAuthenticationService } from "../user-authentication/user-authentication-service";
import { EventListenerEventsService } from "../user-events/event-listener-events-service";
import { UserEventsServiceComposite } from "../user-events/user-events-service-composite";
import { YouTubeService } from "../youtube-service.ts/youtube-service";

/**
 * This handles common dependency wiring so components don't need to do that themselves
 */
export class ComponentDependencyTree {

    public static clientService = new ClientService();
    public static localStorageService = new LocalStorageService();
    public static modalService = new ModalService();
    public static toastService = new ToastService();
    public static youtubeService = new YouTubeService();
    public static eventListenerEventsService = new EventListenerEventsService();

    public static themeService = new ThemeService(this.localStorageService, this.clientService);

    public static clientDispatcherService = new ClientDispatcherService(this.clientService);
    public static userAuthenticationService = new UserAuthenticationService(
        this.clientService,
        this.localStorageService
    );

    public static shareService = new ShareService(
        this.clientService,
        this.modalService
    );

    public static hexakaiPlusSubscriptionService = new HexakaiPlusSubscriptionService(
        this.clientService,
        this.userAuthenticationService
    );

    public static achievementService = new GameAchievementService(
        this.clientService,
        this.localStorageService,
        this.toastService,
        this.clientDispatcherService
    );

    public static userEventsServiceComposite = new UserEventsServiceComposite(
        this.clientService,
        this.localStorageService
    );

    public static hexakaiGameBoardCreatorWebWorker = new HexakaiGameBoardCreatorWebWorker(
        this.userEventsServiceComposite,
        this.clientService,
        this.localStorageService
    );

    public static dailyPuzzleService = new DailyPuzzleService(
        this.hexakaiGameBoardCreatorWebWorker,
        this.clientService
    );

    public static hexakaiPlusDailyPuzzleService = new HexakaiPlusDailyPuzzleService(
        this.clientService,
        this.modalService,
        this.youtubeService,
        this.hexakaiPlusSubscriptionService
    );

    public static settingsService = new SettingsService(
        this.localStorageService,
        this.hexakaiPlusSubscriptionService
    );

    public static sessionService = new SessionService(
        this.clientService,
        this.modalService,
        this.localStorageService,
        this.eventListenerEventsService
    );
}