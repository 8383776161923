import { AdProviders, ClientConfig } from "../models/client-config";
import { WEB_BROWSER_CONFIG } from "./web-browser-config";

export const BOOK_CONFIG: ClientConfig = {
    ...WEB_BROWSER_CONFIG,
    body: {
        ...WEB_BROWSER_CONFIG.body,
        fontOverride: `"Courier Prime", monospace`,
    },
    footer: {
        clientText: "Paper",
        googleAdVisiblePercent: 100,
    },
    gameBoard: {
        ...WEB_BROWSER_CONFIG.gameBoard,
        thickBorders: true,
        boldCellValues: false,
        defaultHexHeight: "max(6vw, 6vh)",
        hexFontMultiplier: 0.4
    },
    ads: {
        requestDisplayPermission: {
            enabled: false,
        },
        adProviders: [{
            provider: AdProviders.google,
            percentage: 90
        }, {
            provider: AdProviders.bookOfTheMonth,
            percentage: 0
        }, {
            provider: AdProviders.authorBook,
            percentage: 10
        }],
        viewPercentage: 0
    },
};