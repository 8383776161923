import { html } from "lit";
import { HexakaiPlusFeatures } from "../hexakai-plus-subscription/hexakai-plus-features";
import { HexakaiPlusSubscriptionService } from "../hexakai-plus-subscription/hexakai-plus-subscription-service";
import { YouTubeService } from "../youtube-service.ts/youtube-service";
import { DailyPuzzleService } from "./daily-puzzle-service";
import { scrapeDate } from "./scrape-date";
import { ClientService } from "../client-service/client-service";
import { ModalService } from "../modal-service/modal-service";

export class HexakaiPlusDailyPuzzleService {

    // stored playlist info
    private playlistVideos = new Map<number, string>();
    private PLAYLIST_ID = `PL2my1Z1neT11Zat-d2pGacqVE1kbKUu8W`;

    constructor(
        private clientService: ClientService,
        private modalService: ModalService,
        private youtubeService: YouTubeService,
        private hexakaiPlusService: HexakaiPlusSubscriptionService
    ) {}

    async isDailyPuzzleUnlocked(ts: number): Promise<boolean> {
        // check if today
        const dateString = DailyPuzzleService.getDateString(ts);
        if (dateString === DailyPuzzleService.getDateString()) {
            return true;
        }

        // check if feature is unlocked
        const isFeatureUnlocked = await this.hexakaiPlusService.isFeatureUnlocked(HexakaiPlusFeatures.allDailyPuzzles);
        if (isFeatureUnlocked) {
            return true;
        }

        // check if youtube video is present
        await this.initVideos();

        const startOfDayTimestamp = DailyPuzzleService.getStartOfDayTimestamp(ts);
        return this.playlistVideos.has(startOfDayTimestamp);
    }

    async getPlaylistVideo(ts: number): Promise<string | null> {
        await this.initVideos();
        const startOfDayTimestamp = DailyPuzzleService.getStartOfDayTimestamp(ts);
        return this.playlistVideos.get(startOfDayTimestamp) || null;
    }

    showLockedModal(timestamp: number): void {
        const displayDate = new Date(timestamp).toLocaleDateString();

        // show modal indicating that this is only available to Hexakai plus subscribers
        const modalId = 'daily-puzzle-locked';
        const title = "This Puzzle is Locked";
        const plusHref = this.clientService.createLocalLink("plus", {}, true);
        const modalTemplate = html`
            <div>
                <p>The puzzle for ${displayDate} is only available with a <a href="${plusHref}">Hexakai Plus</a> subscription.</b>
            </div>
            <br />
            <div style="display: flex; gap: 1em; flex-wrap: wrap">
                <styled-button @click="${() => this.modalService.hideModal(modalId)}">Close</styled-button>
                <styled-button @click="${() => {
                window.location.href = plusHref;
            }}">Get Hexakai Plus</styled-button>
            </div>
        `;

        this.modalService.showModal(
            modalId,
            title,
            modalTemplate
        );
    }

    private initVideos(): Promise<void> {
        if (this.playlistVideos.size > 0) {
            return Promise.resolve();
        }

        return this.youtubeService.getVideosFromPlaylist(
            this.PLAYLIST_ID
        ).then(items => {
            this.playlistVideos = new Map<number, string>();
            for (const video of items) {
                const description = video.snippet.description;
                const date = scrapeDate(description);
                if (!date) {
                    continue;
                }

                const timestamp = DailyPuzzleService.getStartOfDayTimestamp(date);
                const videoId = video.snippet.resourceId.videoId;
                const videoUrl = `https://www.youtube.com/watch?v=${videoId}`;
                this.playlistVideos.set(timestamp, videoUrl);
            }
            console.debug(`[HexakaiPlusDailyPuzzleService] playlist items mapped`, this.playlistVideos);
        });
    }
}