import { LitElement, html, css } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';  // Note the change here

import { HexakaiBoardData } from '../hexakai-board/hexakai-board-data';
import { HexakaiBoardAssignment, HexakaiBoardState } from '../models/hexakai-board-state';
import { HexakaiGameDifficulty, HexakaiGameParams } from '../models/hexakai-game-params';
import { HexakaiBoardValidator } from '../hexakai-board/hexakai-board-validator';
import { ModalLayover } from './modal-layover';
import { HexakaiGameSettings } from './hexakai-game-settings';
import { LocalStorageService } from '../local-storage/local-storage-service';
import { HexakaiGameSession } from '../models/hexakai-game-session';
import { HexakaiGameBoardCreatorWebWorker } from '../hexakai-game/hexakai-game-board-creator-webworker';
import { HexCell } from "./hex-cell";
import { ClientService } from '../client-service/client-service';
import { GameAchievementService } from '../achievements/game-achievement-service';
import { ToastService } from '../toast-service/toast-service';
import { HexakaiGameAction, HexakaiGameActionType } from '../models/hexakai-game-action';
import { HexakaiBoardStateVisitor } from '../hexakai-board/hexakai-board-state-visitor';
import { shuffleInPlace, shuffleToNew } from '../random/shuffle';
import { ClientDispatcherService } from '../client-dispatcher-service/client-dispatcher-service';
import { HexakaiGameSessionSerializer } from '../hexakai-game-session/hexakai-game-session-serializer';
import { getRandom } from '../random/get-random';
import { DailyPuzzleService } from '../daily-puzzle/daily-puzzle-service';
import { ModalService } from '../modal-service/modal-service';
import { ShareService } from '../share-service/share-service';
import { SoundService } from '../sound-service/sound-service';
import { Sounds } from '../sound-service/sounds';
import { SettingsService } from '../settings-service/settings-service';
import { GoogleAdsProviderService } from '../ads-service/google/google-ads-service';
import { EventListener } from '../event-listener/event-listener';
import { RngType } from '../models/rng-params';
import { GENERATOR_PROFILE_DEFAULT, GENERATOR_PROFILE_LOOKUP, GENERATOR_PROFILES, GENERATOR_RANDOM, GENERATOR_STANDARD, GeneratorPatternConfig } from '../hexakai-game/generator-patterns/generator-patterns';
import { INFO_BOX_DIFFICULTY_SIZE_MESSAGE, INFOBOX_SOURCE_MESSAGES_DEFAULT } from '../infobox-messages/infobox-source-messages';
import { HexakaiHintFinder } from '../hexakai-game/hexakai-hint-finder';
import { HexakaiGameSolver } from '../hexakai-game/hexakai-game-solver';
import { HexakaiGameHintType } from '../models/hexakai-game-hint';
import { HexakaiCellValueMapper } from '../hexakai-board/hexakai-cell-value-mapper';
import { CellValueType, Settings } from '../models/settings';
import { GlobalAccessService } from '../global-access-service/global-access-service';
import { SessionService } from '../session-service/session-service';
import { UserEventsServiceComposite } from '../user-events/user-events-service-composite';
import { boardAbandonedEvent } from '../user-events/events/board-abandoned-event';
import { boardCompletedEvent } from '../user-events/events/board-completed-event';
import { boardStartedEvent } from '../user-events/events/board-started-event';
import { dailyBoardAbandonedEvent } from '../user-events/events/daily-board-abandoned-event';
import { dailyBoardCompletedEvent } from '../user-events/events/daily-board-completed-event';
import { dailyBoardStartedEvent } from '../user-events/events/daily-board-started-event';
import { hintClickedEvent } from '../user-events/events/hint-clicked-event';
import { playAgainClickedEvent } from '../user-events/events/play-again-clicked-event';
import { EventListenerEventsService } from '../user-events/event-listener-events-service';
import { boardWrongSubmissionEvent } from '../user-events/events/board-wrong-submission-event';
import { dailyBoardWrongSubmissionEvent } from '../user-events/events/daily-board-wrong-submission-event';
import { formatDuration } from '../format-duration/duration-formatter';
import { UserAuthenticationService } from '../user-authentication/user-authentication-service';
import { HexakaiPlusSubscriptionService } from '../hexakai-plus-subscription/hexakai-plus-subscription-service';
import { HexakaiPlusFeatures } from '../hexakai-plus-subscription/hexakai-plus-features';
import { HexakaiPlusDailyPuzzleService } from '../daily-puzzle/daily-puzzle-hexakai-plus-service';
import { YouTubeService } from '../youtube-service.ts/youtube-service';
import { ComponentDependencyTree } from '../component-dependency-tree/component-dependency-tree';
import { HexakaiUncertaintyMeasurementService } from '../hexakai-game/uncertainty-measurement/hexakai-uncertainty-measurement-service';
import { HexakaiGameCreatorResponse } from '../models/hexakai-game-board-creator';

@customElement('hexakai-board')
export class HexakaiBoard extends LitElement {

    private TRANSITION_DURATION_FLASH = 1000 * 0.3;
    private TRANSITION_DURATION = 1000 * 3;

    // special values for display mode
    private hexCellMarkerValues = new Set(["X", "Z"]);

    private boardData!: HexakaiBoardData;
    private boardAssignment!: HexakaiBoardAssignment;
    private boardVisitor!: HexakaiBoardStateVisitor<string>;
    private solutionAssignment?: HexakaiBoardAssignment;
    private disabledCells!: Set<number>[];
    private gameSettings!: HexakaiGameParams;
    private gameComplete = false;
    private gameIsCheckingComplete = false;
    private hintKey = "game-hint-timestamp";
    private hintDuration = 1_000 * 120;
    private componentInView = false;

    private lastSubmitBoardState: HexakaiBoardState<string> | null = null;

    // client configuration
    private clientService = ComponentDependencyTree.clientService;

    // analytics
    private userEventsServiceComposite = ComponentDependencyTree.userEventsServiceComposite;

    // storage
    private storageService = ComponentDependencyTree.localStorageService;

    // board generator
    private gameCreator = ComponentDependencyTree.hexakaiGameBoardCreatorWebWorker;

    // game defaults
    private DEFAULT_GAME_SETTINGS = {
        gameSize: 7,
        difficulty: HexakaiGameDifficulty.easy
    };

    // control the height of the board via zoom factor
    private zoomAmount = 1;
    // Pinch Zoom properties
    private initialPinchDistance = 0;
    private initialPinchZoomAmount = 1;
    private boardHeight!: string;

    private defaultHexHeight = this.clientService.getConfig().gameBoard.defaultHexHeight;

    // component lifecycle
    private readyToRender = false;
    private gameRendered = false;
    private sessionKey!: string;
    private zoomKey!: string;
    private session!: HexakaiGameSession;
    private soundService = new SoundService();

    private modalService = new ModalService();
    private shareService = new ShareService(
        this.clientService,
        this.modalService
    );

    // achievements
    private achievementService = ComponentDependencyTree.achievementService;

    // user settings
    private settingsService = ComponentDependencyTree.settingsService;
    private hexCellType!: CellValueType;
    private unifiedHexModalEnabled: boolean = false;

    // hints
    private hintFinder = new HexakaiHintFinder(
        new HexakaiGameSolver()
    );

    // daily puzzle
    private dailyPuzzleService = ComponentDependencyTree.dailyPuzzleService;

    // session
    private sessionService = ComponentDependencyTree.sessionService;

    private hexakaiPlusService = ComponentDependencyTree.hexakaiPlusSubscriptionService;

    private youtubeService = ComponentDependencyTree.youtubeService;
    private hexakaiPlusDailyPuzzleService = ComponentDependencyTree.hexakaiPlusDailyPuzzleService;

    // dragging
    @property({ type: Boolean }) private isDragging = false;
    @property({ type: Number }) private scrollStartX = 0;
    @property({ type: Number }) private scrollStartY = 0;
    @property({ type: Number }) private scrollScrollLeft = 0;
    @property({ type: Number }) private scrollScrollTop = 0;
    @property({ type: Number }) private startX = 0;
    @property({ type: Number }) private startY = 0;


    // Properties with decorators to react to changes
    @property({ type: String }) mode = "display";
    @property({ type: String }) board = "";
    @property({ type: Boolean }) private showNewDialogue = false;


    @query('modal-layover.cell-value')
    cellValueModalLayover!: any;

    // CSS for the component
    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            /*-webkit-touch-callout: none;
            touch-action: manipulation;*/
        }

        a {
            color: var(--body-color);
        }

        .game-board-container {
            flex-grow: 1;
            overflow: hidden;
            user-select: none;
            -webkit-user-select: none;
        }

        .game-board {
            padding-top: 15px;
            padding-bottom: 15px;
            position: relative;
            display: inline-flex;
            flex-direction: column;
            /*height: var(--board-height) !important;*/
            /*border: 1px solid;*/
        }

        .hex-row {
            display: flex;

            justify-content: center;
        }

        hex-cell {
            /*flex-grow: 1;*/
            --body-color: var(--color);
            font-weight: var(--hex-value-font-weight, initial);
            --hover-color: var(--hex-hover-color);
            --background-color-disabled: var(--hex-disabled-color);
            --background-color: var(--hex-color);
        }

        loading-display {
            width: 100%;
            height: 100%;
        }

        styled-button {
            --font-size: 0.85em;
            --button-font: var(--font);
        }

        .zoom-row-container {
            user-select: none;
            -webkit-user-select: none;

            position: absolute;
            left: 5%;
            padding-top: 20px;

            display: inline-flex;
            width: 90%;
            justify-content: space-between;
        }

        .dual-icons, .dual-icons-larger {
            z-index: 1;
            padding: 0.3em;
        }

        .dual-icons img {
            cursor: pointer;
            height: 1.9em;
            transition: height .2s;
            filter: var(--icon-color-filter);
        }

        .dual-icons-larger img {
            cursor: pointer;
            height: 2.4em;
            transition: height .2s;
            filter: var(--icon-color-filter);
        }

        .dual-icons img:hover {
            height: 2.5em;
            transition: height .2s;
        }

        .dual-icons-larger img:hover {
            height: 2.9em;
            transition: height .2s;
        }

        .game-actions-container {
            position: absolute;
            bottom: 5%;
            left: 5%;
            width: 430px;
            z-index: 100;
        }
        .game-actions-buttons styled-button {
            width: 150px;
            margin-bottom: 5px;
        }

        .game-submit-button {
            width: 308px !important;
        }

        info-box {
            --text-align: center;
        }

        .modal-value-selected {
            --button-background-color: var(--button-hover-background-color);
        }

        /* If not wide enough, move action bar to the bottom and hide ad bar */
        @media screen and (max-width: 1200px) {
            .dual-icons img {
                height: max(3vh, 3vw);
            }
    
            .dual-icons-larger img {
                height: max(3.8vh, 3.8vw);
            }
    
            .dual-icons img:hover {
                height: max(3.4vh, 3.4vw);
            }
    
            .dual-icons-larger img:hover {
                height: max(4.4vh, 4.4vw);
            }

            .game-actions-container {
                width: 100%;
                left: 0;
                bottom: 0;
                top: unset;
            }

            .game-actions-button-br {
                display: none;
            }

            .game-actions-buttons {
                margin-bottom: 5px;
            }

            .game-actions-buttons styled-button {
                width: 19%;
                font-size: 0.9em;
                --padding: 10px 10px;
                margin-bottom: 0;
            }

            .game-submit-button {
                width: 19% !important;
            }

            info-box {
                font-size: 0.9em;
                --height: 3.5vh;
            }

            .game-new-text {
                display: none;
            }
        }
    `;


    /**
     * When first connected, load data and take care of other preliminary things
     */
    connectedCallback(): void {
        super.connectedCallback();

        const intersectionObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // Logic to run when the component scrolls into view
                        this.initializeComponent();

                        // Disconnect the observer once the element is in view
                        intersectionObserver.disconnect();

                        this.componentInView = true;
                        this.requestUpdate();
                    }
                });
            },
            { root: null, threshold: 0.1 }
        );

        intersectionObserver.observe(this);
    }

    private initializeComponent(): void {
        console.log("[HexakaiBoard] connected and in view");
        this.settingsService.onSettingsUpdated((settings: Settings) => {
            console.log(`[HexakaiBoard] settings update received`, settings);
            this.hexCellType = settings.cellValueType;
            this.unifiedHexModalEnabled = settings.unifiedCellMenuEnabled;
            this.requestUpdate();
        });

        if (this.mode === 'game') {
            EventListener.add(document, 'keydown', 'code', ({ code }: any) => {
                if (!this?.session?.boardState) {
                    return;
                }

                if (code === "Enter" || code === "NumpadEnter") {
                    this.onCheckStatusClick();
                }
            });

            this.initializeZoom();
            this.initializeFirstGame();
        } else {
            this.settingsService.getSettings()
                .then(s => s.cellValueType)
                .then(t => {
                    this.hexCellType = t;
                    this.readyToRender = true;
                    this.requestUpdate();
                });
            this.adjustBoardHeight();
        }

        // if we allow the solver, put objects on the window 
        this.registerGlobalHandlers();
        window.addEventListener('resize', () => this.adjustBoardHeight());
    }

    private registerGlobalHandlers() {
        GlobalAccessService.getInstance().registerGlobalHandlers({
            board: {
                fillValues: this.fillSolution.bind(this),
                getHints: async () => {
                    const hints = await this.hintFinder.getHints(
                        this.session
                    );
                    console.log(hints);
                },
                countValues: () => {
                    const counts = new Map<string, number>();
                    for (const value of this.boardData.getCellValues()) {
                        counts.set(
                            HexakaiCellValueMapper.getValue(value, this.hexCellType),
                            [...this.shadowRoot!.querySelectorAll(`hex-cell[value="${value}"]`)].length
                        )
                    }
                    console.log("Counts:", counts);
                },
                colorPossibleValues: (value: string, colorNumber?: number) => {
                    this.colorPossibleValues(`${value}`, colorNumber);
                },
                setValues: (templateString: string) => {
                    this.setCustomBoardContents(templateString);
                },
                getBoardData: () => this.boardData,
                boardToCsv: () => {
                    return this.boardData.boardStateToCsv(this.session.boardState!);
                },
                boardToSimpleString: () => {
                    return this.boardData.boardStateToSimpleString(this.session.boardState!);
                },
                serialize: () => {
                    const serialized = HexakaiGameSessionSerializer.serialize(
                        this.session,
                        {
                            includeFilledColors: false,
                            includeFilledValues: false,
                            includePencilValues: false
                        }
                    );
                    const deserialized = HexakaiGameSessionSerializer.deserialize(
                        serialized
                    );

                    return { serialized, deserialized };
                },
                getSession: () => this.session,
                getElement: () => this,
                zoom: (zoomIn: boolean) => this.zoom(zoomIn),
                determineUncertainty: () => {
                    const service = new HexakaiUncertaintyMeasurementService();
                    const score = service.determineUncertainty(this.session.boardState!);
                    console.log("Uncertainty score:", score);
                    return score;
                },
                getUrl: () => this.getBoardUrl(),
                checkAmbiguity: () => {
                    const solver = new HexakaiGameSolver();
                    const solutions = solver.solve(this.session.boardState!);
                    console.log("[HexakaiBoard] solution(s)", solutions);
                    return solutions.length === 1;
                }
            }
        });
    }

    private async initializeZoom(): Promise<void> {
        this.zoomKey = `${this.id}-zoom`;
        const savedZoom = await this.storageService.get(this.zoomKey) as any;
        if (savedZoom) {
            this.zoomAmount = savedZoom.zoom;
        }
    }

    private async initializeFirstGame(): Promise<void> {
        let incomingSession: any = "";
        this.sessionKey = `${this.id}-saved-board`;

        // check if there is a serialized game in the params
        const url = new URL(window.location.href);
        const bParam = url.searchParams.get('b');
        const dParam = url.searchParams.get('d');
        const pParam = url.searchParams.get('p');

        if (bParam !== null) {
            url.searchParams.delete('b');
            url.searchParams.delete('d');
            url.searchParams.delete('p');

            incomingSession = bParam;

            // Update the URL in the browser without reloading the page
            window.history.replaceState({}, '', url.toString());
            console.log("[HexakaiBoard] caught game serialization in params", incomingSession);
        } else if (dParam !== null) {
            const dailyValue = dParam === "daily"
                ? +new Date()
                : dParam;

            const ts = DailyPuzzleService.getStartOfDayTimestamp(dailyValue);
            const dailyPuzzleSession = await this.dailyPuzzleService.getPuzzleSession(ts);

            // update the session with the daily board
            //await this.storageService.set(this.sessionKey, dailyPuzzleSession);
            console.log("[HexakaiBoard] daily board in params, so trying to open", dParam, dailyPuzzleSession);
            const isUnlocked = await this.hexakaiPlusDailyPuzzleService.isDailyPuzzleUnlocked(ts);
            if (!isUnlocked) {
                this.hexakaiPlusDailyPuzzleService.showLockedModal(ts);
            } else {
                incomingSession = dailyPuzzleSession;
            }
        } else if (pParam !== null && this.clientService.getConfig().gameBoard.pParamEnabled) {
            url.searchParams.delete('b');
            url.searchParams.delete('d');
            url.searchParams.delete('p');

            const params = JSON.parse(pParam);
            console.log("[HexakaiBoard] params in params, so trying to open", pParam, params);

            if (params.gameSize && params.difficulty) {
                params.gameSize = parseInt(params.gameSize);
                incomingSession = params;
            }
        }

        this.session = await this.storageService.get(this.sessionKey) as HexakaiGameSession;
        if (incomingSession) {
            await this.tryUpdateSessionFromIncoming(incomingSession);
        }

        // if there is a param for holding, hold until enter
        const holdParam = url.searchParams.get('hold');
        if (holdParam !== null) {
            url.searchParams.delete('hold');
            console.log("[HexakaiBoard] caught hold parameter, so holding");
            // don't generate the board until enter is clicked
            const existingStyle = this.style.visibility;
            this.style.visibility = "hidden";
            await new Promise<void>(resolve => {
                const listener = EventListener.add(document, 'keydown', 'code', (({ code }: any) => {
                    if (code === "Space" || code === "Enter" || code === "NumpadEnter") {
                        listener.unsubscribe();
                        resolve();
                    }
                }));
            });
            this.style.visibility = existingStyle;
        }

        await this.initializeGame();
    }

    /**
     * Initialize a game, either from a saved session, or from params
     */
    private async initializeGame(): Promise<void> {
        console.log("[HexakaiBoard] initializing game");
        let initType: string;

        // get hex cell type
        const settings = await this.settingsService.getSettings();
        this.hexCellType = settings.cellValueType;
        this.unifiedHexModalEnabled = settings.unifiedCellMenuEnabled;

        // show loading display
        this.readyToRender = false;
        this.requestUpdate();

        await new Promise(r => setTimeout(r, 4));

        this.gameComplete = false;
        // load the session
        let session = await this.storageService.get(this.sessionKey) as HexakaiGameSession;
        if (!session) {
            session = this.createSessionParams();
        }

        this.gameSettings = session.params;

        // initialize the undo-redo stack if needed
        if (!session.undoRedo) {
            session.undoRedo = {
                redoStack: [],
                undoStack: []
            };
        }

        // init start time if needed
        if (!session.boardStartTime) {
            session.boardStartTime = +new Date();
        }

        // add in random seeds if needed
        if (!session.params.generatorId) {
            session.params.generatorId = GENERATOR_PROFILE_DEFAULT.generatorId
        }

        // make the game object
        if (session.boardState) {
            this.boardAssignment = session.boardState!;
            this.solutionAssignment = session.solution;
            initType = "existing";
        } else {
            initType = "new";
            // add in any defaults needed
            // TODO: is this redundant from what's a bove?
            if (!session.params.generatorId) {
                session.params.generatorId = GENERATOR_PROFILE_DEFAULT.generatorId
            }

            const isFirstSession = await this.sessionService.isFirstAppSession();

            let profile: GeneratorPatternConfig;
            if (isFirstSession) {
                console.log(`[HexakaiBoard] this is the first game in the session, so using default generator`);
                profile = GENERATOR_PROFILE_DEFAULT;
            } else {
                profile = GENERATOR_PROFILE_LOOKUP[session.params.generatorId]
                    || GENERATOR_PROFILE_DEFAULT;

                // if this is a random selection, pick a random generator
                if (profile.generatorId === GENERATOR_RANDOM.generatorId) {
                    const generators = shuffleToNew([
                        ...GENERATOR_PROFILES,
                        // add bias toward standard generator
                        GENERATOR_STANDARD,
                        GENERATOR_STANDARD,
                        GENERATOR_STANDARD,
                        GENERATOR_STANDARD,
                        GENERATOR_STANDARD
                    ]);
                    let i = 0;
                    do {
                        profile = generators[i++]
                    } while (!profile.allowedContexts.find(cxt => {
                        return cxt.difficulty === session.params.difficulty && cxt.gameSize === session.params.gameSize
                    }));
                }

                if ((profile.valueRandomSeed as any)?.data) {
                    (profile.valueRandomSeed as any).data.gameSize = session.params.gameSize;
                }

                if ((profile.hintRandomSeed as any)?.data) {
                    (profile.hintRandomSeed as any).data.gameSize = session.params.gameSize;
                }
            }

            // TODO: this is a workaround for an unidentified edge case
            let tryCount = 0;
            const generate: () => Promise<HexakaiGameCreatorResponse> = async () => {
                return this.gameCreator.create(
                    session.params,
                    profile.valueRandomSeed,
                    profile.hintRandomSeed
                ).then(({challenge, solution}) => {
                    // validate the cells are in place
                    if (!challenge.cells) {
                        throw "Cells are empty, problem generating board!";
                    }
                    return {challenge, solution};
                }).catch(err => {
                    tryCount ++;
                    if (tryCount > 2) {
                        throw err;
                    }
                    return generate();
                });
            }

            const { challenge, solution } = await generate();

            this.boardAssignment = challenge;
            this.solutionAssignment = solution;
        }
        this.boardData = new HexakaiBoardData(session.params.gameSize);

        // update the session's disabled cells
        if (session.disabledCells) {
            this.disabledCells = session.disabledCells.map(
                row => new Set(row)
            );
        } else {
            this.disabledCells = [];
            for (let row = 0; row < this.boardData.getNumRows(); row++) {
                this.disabledCells.push(new Set());
                for (let col = 0; col < this.boardData.getNumCols(row); col++) {
                    if (!!this.boardAssignment.cells[row][col]) {
                        this.disabledCells[row].add(col);
                    }
                }
            }

            session.disabledCells = this.disabledCells.map(
                row => [...row]
            );
        }

        // update the user set cell colors
        if (session.cellColors) {
            session.cellColors = session.cellColors;
        } else {
            session.cellColors = [];
            for (let row = 0; row < this.boardData.getNumRows(); row++) {
                session.cellColors.push([]);
                for (let col = 0; col < this.boardData.getNumCols(row); col++) {
                    session.cellColors[row][col] = 0;
                }
            }
        }

        // update pencil markings
        if (!session.pencilMarks) {
            session.pencilMarks = [];
            for (let row = 0; row < this.boardData.getNumRows(); row++) {
                session.pencilMarks.push([]);
                for (let col = 0; col < this.boardData.getNumCols(row); col++) {
                    session.pencilMarks[row].push([]);
                }
            }
        }

        // update the session's board data
        session.boardState = this.boardAssignment;
        session.solution = this.solutionAssignment;
        this.boardVisitor = new HexakaiBoardStateVisitor(this.boardAssignment);

        // save the session
        this.storageService.set(this.sessionKey, session);
        this.session = session;

        // show or hide the menu highlight
        // TODO: a little hacky putting this here
        this.clientService.setIsDailyContext(
            !!session.dailyPuzzleTimestamp
        );

        // remove loading display
        this.readyToRender = true;
        this.gameRendered = false;
        this.settingsService.getSettings().then(({ boardSoundsEnabled }) => {
            if (boardSoundsEnabled) {
                this.soundService.play(Sounds.start);
            }
        });

        this.requestUpdate();
        await this.updateComplete;
        this.scrollToCenter();
        this.adjustBoardHeight();

        if (this.showNewDialogue) {
            this.showNewDialogue = false;
            this.onNewGameClick();
        }

        this.dispatchEvent(new CustomEvent('game-initialized', {
            detail: {
                params: this.session.params,
                boardState: this.session.boardState!,
                initType
            }
        }));
    }

    private scrollToCenter(): void {
        const containerEl = this.shadowRoot?.querySelector(".game-board-container")!;
        const boardEl = this.shadowRoot?.querySelector(".game-board")!;
        const scrollPosition = (boardEl.clientWidth - this.clientWidth) / 2;
        containerEl.scrollTo({ left: scrollPosition });
    }

    /**
     * Stop loading a game
     */
    private async stopInitializingGame(): Promise<void> {
        console.log("[HexakaiBoard] game cancellation requested");
        await this.storageService.set(this.sessionKey, {
            params: this.DEFAULT_GAME_SETTINGS
        });

        // TODO: this is HACKY!
        const link = this.clientService.createLocalLink(
            "app",
            {},
            true
        );
        window.location.href = link;
    }

    /**
     * Render the element
     */
    render() {
        if (!this.componentInView) {
            return ``;
        }

        if (!this.readyToRender) {
            return html`
                <loading-display>
                    <p>Generating your board.</p>
                    <p>This may take longer for larger boards and higher difficulties, especially if advanced options are used.</p>
                    <styled-button @click=${this.stopInitializingGame}>Cancel</styled-button>
                </loading-display>
            `;
        }

        console.log("[HexakaiBoard] rendering contents");

        let n: number;
        let boardCells: HexakaiBoardAssignment;

        if (this.mode === 'game') {
            boardCells = this.boardAssignment;
        } else {
            // determine the game size fro the input string (assuming it's valid)
            n = Math.sqrt(this.board.length);
            this.boardData = new HexakaiBoardData(n);

            // create the board itself
            let index = 0;
            boardCells = this.boardData.createBoardState(() => {
                return this.board[index++];
            });
        }

        return this.renderVertical(this.boardData, boardCells);
    }

    /**
     * n = game size
     * Render the vertical version of this.
     * Moving here, I may add a horizontal representation in the future to help with wide screens
     */
    private renderVertical(boardData: HexakaiBoardData, boardCells: HexakaiBoardAssignment) {
        // create the cell elements, calcluating for width
        // TODO: determine 
        const nr = boardData.getNumRows();

        let hexHeight: string;

        if (this.mode !== 'game') {
            hexHeight = this.style.getPropertyValue("--hex-height");
        } else {
            hexHeight = `calc(${this.defaultHexHeight} * ${this.zoomAmount})`;
        }

        const hexSizeStyle = `height: ${hexHeight}`;
        const hexFontSize = `calc(${hexHeight} * ${this.clientService.getConfig().gameBoard.hexFontMultiplier})`;

        if (!this.gameRendered) {
            this.updateComplete.then(() => {
                this.adjustBoardHeight();
            });
        }

        const doFlash = !this.gameRendered;
        this.gameRendered = true;
        const visibility = doFlash ? "visibility: hidden;" : "";

        let thickBorderStyle = "";
        if (this.clientService.getConfig().gameBoard.thickBorders) {
            thickBorderStyle = "--hex-border-increase: 8px";
        }

        let index = 0;
        return html`
            ${this.getGameUndoRedoZoomRow()}
            <div class="game-board-container"
                style="cursor: ${this.mode === 'game' ? "grab" : "default"};"
                @mousedown="${this.startDrag}"
                @mouseup="${this.stopDrag}"
                @mouseleave="${this.stopDrag}"
                @mousemove="${this.handleDrag}"
                @touchstart="${this.startTouchDrag}"
                @touchend="${this.stopDrag}"
                @touchcancel="${this.stopDrag}"
                @touchmove="${this.handleTouchDrag}"
            >
                <div class="game-board" style="height: ${this.boardHeight};">
                ${Array.from({ length: nr }, (_, row) => html`
                    <div class='hex-row' style="position: relative; transform: translateY(-${25 * row}%)">
                    ${Array.from({ length: boardData.getNumCols(row) }, (_, col) => html`
                        <hex-cell
                            style="--hex-inner-border-color: var(${this.session?.disabledCells![row].includes(col) ? '--hex-cell-disabled-inner-border-color' : '--hex-cell-enabled-inner-border-color'});${thickBorderStyle};${this.session?.cellColors?.[row]?.[col]! > 0 ? `--hex-choice-color-override: var(--color-note-${this.session.cellColors![row][col]});` : ''}${visibility}; ${hexSizeStyle}; --font-size: ${hexFontSize};"
                            row="${row}"
                            col="${col}"
                            value="${boardCells.cells[row][col]}"
                            .cellValueType="${this.hexCellType}"
                            disabled="${this.mode !== 'game' && (boardCells.cells[row][col] !== ' ' && !this.hexCellMarkerValues.has(boardCells.cells[row][col])) || this.mode === 'game' && this.disabledCells[row]?.has(col)}"
                            @mouseover="${(e: Element) => this.onHexHoverStart(row, col)}"
                            @mouseout="${(e: Element) => this.onHexHoverEnd(row, col)}"
                            @click="${(e: Event) => this.onCellClick(
            e,
            boardCells.cells[row][col],
            row,
            col
        )}"
                            @contextmenu="${(e: Event) => this.onCellRightClick(
            e,
            row,
            col
        )}"
                            @double-click="${(e: Event) => this.onCellRightClick(
            e,
            row,
            col
        )}"
                            @left-right-click="${(e: Event) => this.onCellLeftRightClick(
            e,
            row,
            col
        )}"
                            .pencils="${new Set(this.session?.pencilMarks?.[row][col] || [])}"
                            .onConnected="${(hex: HexCell) => setTimeout(() => {
            if (!doFlash) {
                return;
            }

            const prevOverride = hex.style.getPropertyValue("--hex-choice-color-override");
            hex.style.removeProperty("--hex-choice-color-override");
            hex.pulse();
            const prevStyle = hex.style.getPropertyValue("--background-color");
            hex.style.setProperty("--background-color", "var(--hex-neutral-color)");
            hex.style.visibility = "unset";
            setTimeout(() => {
                hex.style.setProperty("--background-color", prevStyle);
                if (this.session?.cellColors?.[row]?.[col]! > 0) {
                    hex.style.setProperty("--hex-choice-color-override", prevOverride);
                }
            }, this.TRANSITION_DURATION_FLASH);
        }, 15 * index++ /* TODO: move 15 to const */)}"
                        ></hex-cell>
                    `)}
                    </div>
                `)}
                </div>
            </div>
            ${this.getGameActions()}
            <modal-layover class="cell-value"></modal-layover>
        `;
    }

    /**
     * Highlight hexes touching this in rows and diagonals on hover
     */
    private onHexHoverStart(row: number, col: number): void {
        this.showHideCellRowDiagonalOutlines(row, col, true);
    }

    private onHexHoverEnd(row: number, col: number): void {
        this.showHideCellRowDiagonalOutlines(row, col, false);
    }

    private showHideCellRowDiagonalOutlines(row: number, col: number, doShow: boolean): void {
        if (this.mode !== 'game') {
            return;
        }

        // only show if config is enabled
        // TODO: need to clean up the colors / UI presentation in this feature
        if (!this.clientService.getConfig().gameBoard.showCellConstraintsHover) {
            return;
        }

        for (const [_value, cRow, cCol] of [
            ...this.boardVisitor.visitRowForward(row, 0, false),
            ...this.boardVisitor.visitColLeft(row, col),
            ...this.boardVisitor.visitColRight(row, col)
        ]) {
            const hex: HexCell = this.shadowRoot!.querySelector(`hex-cell[row='${cRow}'][col='${cCol}']`)!;

            if (doShow) {
                hex.displayInnerBorder();
            } else {
                hex.hideInnerBorder();
            }

        }
    }

    private getGameUndoRedoZoomRow() {
        if (this.mode !== 'game') {
            return "";
        }

        return html`
            <div class="zoom-row-container">
                <div class="dual-icons" id="undo-redo-container">
                    <img src="./undo.svg" @click="${() => this.onUndoClick()}">
                    <img src="./redo.svg" @click="${() => this.onRedoClick()}">
                </div>
                <div class="dual-icons-larger" id="zoom-container">
                    <img src="./zoom-in.svg" @click="${() => this.zoom(true)}">
                    <img src="./zoom-out.svg" @click="${() => this.zoom(false)}">
                </div>
            </div>
        `;
    }

    private getGameActions() {
        if (this.mode !== 'game') {
            return "";
        }

        const sourceMessages: any = [
            ...INFOBOX_SOURCE_MESSAGES_DEFAULT,
            this.clientService.getConfig().gameBoard.colorInfoMessage,
            this.clientService.getConfig().gameBoard.zoomMessage,
            html`Check out the daily puzzle playthroughs and speedruns on <a href="https://www.youtube.com/@brandon-quinn-author" target="_blank">YouTube</a>!`,
            html`If you have any questions, feedback, or feature requests, please <a href="/contact">contact us</a>.`,
        ];

        if (this.clientService.getConfig().gameBoard.showAppMessage) {
            sourceMessages.push(
                html`This game is also available on the <a href="https://play.google.com/store/apps/details?id=com.hexakai.andrioid.app" target="_blank">Android</a> and <a href="https://apps.apple.com/us/app/hexakai/id6504585426" target="_blank">Apple</a> app stores.`
            )
        }

        const messages = shuffleToNew(sourceMessages);
        messages.unshift(() => INFO_BOX_DIFFICULTY_SIZE_MESSAGE(
            this.session.params.difficulty,
            this.session.params.gameSize,
            this.hexCellType
        ));

        return html`
            <div class="game-actions-container">
                <div class="game-actions-buttons">
                    <styled-button id="new-game-button" @click="${() => this.onNewGameClick()}"><span>New<span class="game-new-text"> Game</span></span></styled-button>
                    <styled-button id="share-button" @click="${() => this.onShareClick()}">Share</styled-button>
                    
                    <br class='game-actions-button-br'>

                    <styled-button id="hint-button" @click="${() => this.onHintClick()}">Hint</styled-button>
                    <styled-button id="reset-button" @click="${() => this.onResetClick()}">Reset</styled-button>

                    <br class='game-actions-button-br'>

                    <styled-button id="submit-button" class="game-submit-button" @click="${() => this.onCheckStatusClick()}">Submit</styled-button>
                </div>
                <info-box style="display: block" id="info-box" .messages="${messages}"></info-box>
            </div>
        `;
    }

    private getHexColorModalTemplate(
        e: Event,
        row: number,
        col: number,
        modal: ModalLayover,
        closeOnClick: boolean
    ): { template: any; onDisplay: (modal: ModalLayover) => any } {
        const hexCell = e.currentTarget! as HexCell;

        let prevOverride = hexCell.style.getPropertyValue("--hex-choice-color-override");
        hexCell.style.setProperty("--hex-choice-color-override", "var(--hex-hover-color)");

        // need to inline styles due to shadow dom
        const modalContents = html`
            <style>
                .select-color-choices, .pencil-container-choices {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                }
        
                .select-color-choices styled-button, .pencil-container-choices styled-button {
                    width: 55px;
                    height: 55px;
                    font-size: 1.2em;
                    margin: 10px;
                    --padding: 12px;
                    --radius: 100%;
                }

                .color {
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                }

                .eraser-img {
                    filter: var(--icon-color-filter);
                    position: relative;
                    transform: translateX(-1px);
                }

                @media screen and (max-width: 1200px) {
                    .select-color-choices styled-button, .pencil-container-choices styled-button {
                        width: 70px;
                        font-size: 1.2em;
                        margin: 5px;
                    }
                }
            </style>
            <div class="select-color-choices">
                ${new Array(9).fill(0).map((_, i) => html`
                    <styled-button class="modal-color-button ${i + 1 === this.session?.cellColors?.[row][col] ? "modal-value-selected" : ""}" @click="${(event: any) => {
                this.onCellColorSelected(
                    hexCell,
                    i + 1,
                    row,
                    col,
                    closeOnClick
                );

                prevOverride = `var(--color-note-${i + 1})`;

                event.target?.blur();
                event.currentTarget?.blur();

                for (const btn of modal.querySelectorAll(".modal-color-button")) {
                    btn.classList.remove("modal-value-selected");
                }
                event.target?.classList.add("modal-value-selected");
                event.currentTarget?.classList.add("modal-value-selected");

                if (closeOnClick) {
                    modal.hide();
                }
            }
            }"><span class="color" style="background-color: var(--color-note-${i + 1});"></span></styled-button>
                `)}
                <styled-button @click="${() => {
                this.onCellColorSelected(
                    hexCell,
                    0,
                    row,
                    col,
                    closeOnClick
                );

                prevOverride = "";

                for (const btn of modal.querySelectorAll(".modal-color-button")) {
                    btn.classList.remove("modal-value-selected");
                }

                if (closeOnClick) {
                    modal.hide();
                }
            }
            }"><img class="eraser-img" src="./eraser.svg" /></styled-button>
            </div>
            <hr style="width:95%"/>
            <h2>Select Pencil Markings</h2>
            <div class="pencil-container-choices">
                ${this.boardData.getCellValues()
                .map(value => [value, HexakaiCellValueMapper.getValue(value, this.hexCellType)])
                .map(([canonicalValue, value]) => (
                    html`<styled-button
                        class="${this.session.pencilMarks![row][col].includes(canonicalValue) ? 'modal-value-selected' : ''}"
                        @click="${(event: any) => {
                            event.target?.blur();
                            event.currentTarget?.blur();
                            this.togglePencilMarking(event, row, col, canonicalValue);
                        }}">${value}
                    </styled-button>`
                ))}
            </div>
        `;

        const onDisplay = (modal: ModalLayover) => {
            modal.addEventListener("modal-hide", () => {
                for (const btn of modal.querySelectorAll(".modal-color-button")) {
                    btn.classList.remove("modal-value-selected");
                }

                if (closeOnClick) {
                    return;
                }

                if (prevOverride) {
                    hexCell.style.setProperty("--hex-choice-color-override", prevOverride);
                } else {
                    hexCell.style.removeProperty("--hex-choice-color-override");
                }
            }, { once: true });
        }

        return { template: modalContents, onDisplay };

    }

    private getHexValueModalTemplate(
        e: Event,
        value: string,
        row: number,
        col: number,
        modal: ModalLayover,
        closeOnClick: boolean,
    ): { template: any; onDisplay: (modal: ModalLayover) => any } {
        const hexCell = e.currentTarget! as HexCell;

        // need to inline styles due to shadow dom
        const modalContents = html`
            <style>
                .select-value-choices {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                }
        
                .select-value-choices styled-button {
                    width: 55px;
                    height: 55px;
                    font-size: 1.5em;
                    margin: 10px;
                    --radius: 100%;
                }

                .eraser-img {
                    filter: var(--icon-color-filter);
                    position: relative;
                    transform: translateX(-1px);
                }

                @media screen and (max-width: 1200px) {
                    .select-value-choices styled-button {
                        width: 70px;
                        font-size: 1.2em;
                        margin: 5px;
                    }
                }
            </style>
            <div class="select-value-choices">
                ${this.boardData.getCellValues()
                .map(v => HexakaiCellValueMapper.getValue(v, this.hexCellType))
                .map(choice => html`
                    <styled-button id="modal-value-button-${choice}" class="modal-value-button ${value === choice ? "modal-value-selected" : ""}" @click="${(event: any) => {
                        this.onCellValueSelected(
                            hexCell,
                            choice,
                            row,
                            col
                        );

                        event.target?.blur();
                        event.currentTarget?.blur();

                        for (const btn of modal.querySelectorAll(".modal-value-button")) {
                            btn.classList.remove("modal-value-selected");
                        }
                        event.target?.classList.add("modal-value-selected");
                        event.currentTarget?.classList.add("modal-value-selected");

                        if (closeOnClick) {
                            modal.hide();
                        }
                    }
                    }" @contextmenu="${(e: Event) => {
                        e.preventDefault();
                        this.onCellValueSelected(hexCell, choice, row, col);
                        this.onCellColorSelected(hexCell, 1, row, col, true);
                        modal.hide();
                    }}"><b>${choice}</b></styled-button>
                `)}
                <styled-button @click="${() => {
                this.onCellValueSelected(
                    hexCell,
                    "",
                    row,
                    col
                );

                for (const btn of modal.querySelectorAll(".modal-value-button")) {
                    btn.classList.remove("modal-value-selected");
                }

                if (closeOnClick) {
                    modal.hide();
                }
            }
            }"><img class="eraser-img" src="./eraser.svg" /></styled-button>
            </div>
        `;

        // Set the template to modal-layover's content

        const onDisplay = (modal: ModalLayover) => {

            // setup keyboard listener
            const validKeys = this.boardData.getCellValues().map(value => value.toString().toUpperCase());
            const keyListener = (event: any) => {
                let keyPressed = event.key.toUpperCase();
                if (keyPressed === ".") {
                    keyPressed = '0';
                }

                if (validKeys.includes(keyPressed)) {
                    console.log(`[HexakaiBoard]: key pressed - ${keyPressed}`);
                    this.onCellValueSelected(hexCell, keyPressed, row, col);
                    document.removeEventListener('keydown', keyListener);
                    modal.hide();
                }
            };

            document.addEventListener('keydown', keyListener);
            modal.addEventListener('modal-hide', () => {
                for (const btn of modal.querySelectorAll(".modal-value-button")) {
                    btn.classList.remove("modal-value-selected");
                }

                document.removeEventListener('keydown', keyListener);
            }, { once: true });
        }

        return {
            template: modalContents,
            onDisplay
        }
    }

    private async onCellClick(
        e: Event,
        value: string,
        row: number,
        col: number
    ): Promise<void> {
        if (this.mode === 'display' || this.disabledCells[row].has(col) || this.gameComplete) {
            return;
        }

        const hexCell = e.currentTarget! as HexCell;
        const prevOverride = hexCell.style.getPropertyValue("--hex-choice-color-override");

        const templates: any[] = [];
        const onDisplayCallbacks: Function[] = [];

        const valueModal = this.getHexValueModalTemplate(
            e,
            value,
            row,
            col,
            this.cellValueModalLayover,
            !this.unifiedHexModalEnabled
        );
        templates.push(valueModal.template);
        onDisplayCallbacks.push(valueModal.onDisplay);

        const title = "Select a Value";

        if (this.unifiedHexModalEnabled) {
            templates.push(html`<hr style="width:95%" /><h2>Select a Color</h2>`);
            const colorModal = this.getHexColorModalTemplate(
                e,
                row,
                col,
                this.cellValueModalLayover,
                false
            );
            templates.push(colorModal.template);
            onDisplayCallbacks.push(colorModal.onDisplay);
            templates.push(html`<hr style="width:95%" /><styled-button @click="${() => this.cellValueModalLayover.hide()}">Save and Close</styled-button>`);
        } else {
            hexCell.style.setProperty("--hex-choice-color-override", "var(--hex-hover-color)");
        }

        // show modal
        onDisplayCallbacks.forEach(c => c(this.cellValueModalLayover));

        // force rerender
        this.cellValueModalLayover.contentTemplate = html``;
        this.cellValueModalLayover.requestUpdate();
        await this.cellValueModalLayover.updatecomplete;

        // add new template
        this.cellValueModalLayover.contentTemplate = templates;
        this.cellValueModalLayover.title = title;

        this.cellValueModalLayover.show();

        // only do this if the color modal doesn't take care of it
        if (!this.unifiedHexModalEnabled) {
            this.cellValueModalLayover.addEventListener("modal-hide", () => {
                hexCell.style.setProperty("--hex-choice-color-override", prevOverride);
            }, { once: true });
        }
    }

    private onCellValueSelected(
        hexElement: HexCell,
        value: string,
        row: number,
        col: number,
    ): void {
        value = HexakaiCellValueMapper.getCanonicalValue(value, this.hexCellType);
        console.log("[HexakaiBoard] cell value selected", { row, col, value });

        const oldValue = this.boardAssignment.cells[row][col];

        // if this is a no-op, exit early
        if (oldValue === value) {
            return;
        }

        this.setCellValue(hexElement, value, row, col);

        this.markBoardActionTaken();

        this.session.undoRedo!.redoStack = [];
        this.session.undoRedo!.undoStack.push({
            type: HexakaiGameActionType.value,
            oldValue,
            newValue: value,
            row,
            col
        });

        this.storageService.set(this.sessionKey, this.session);
    }

    /**
     * Set a cell value and flash the element
     * This doesn't update the session
     */
    private setCellValue(
        hexElement: HexCell,
        value: string,
        row: number,
        col: number
    ): void {
        console.log("[HexakaiBoard] setting cell value", { row, col, value });

        hexElement.setAttribute("value", value);
        hexElement.pulse();

        this.boardAssignment.cells[row][col] = value;
        this.session.boardState!.cells[row][col] = value;
    }

    private async onCheckStatusClick(): Promise<void> {
        if (this.gameComplete || this.gameIsCheckingComplete) {
            return;
        }

        const endTime = +new Date();
        this.gameIsCheckingComplete = true;
        const completionTime = this.session.boardStartTime
            ? (endTime - this.session.boardStartTime)
            : 0;

        const isValid = HexakaiBoardValidator.isValid(this.boardAssignment);
        console.log("[HexakaiBoard] progress check : ", isValid);

        if ((await this.settingsService.getSettings()).boardSoundsEnabled) {
            await isValid
                ? this.soundService.play(Sounds.success).resolveOnStart()
                : this.soundService.play(Sounds.error).resolveOnStart();
        }

        let index = 0;

        for (let row = 0; row < this.boardData.getNumRows(); row++) {
            for (let col = 0; col < this.boardData.getNumCols(row); col++) {
                // if invalid, flash red
                if (!isValid && !this.disabledCells[row].has(col)) {
                    const hex: HexCell = this.shadowRoot!.querySelector(`hex-cell[row='${row}'][col='${col}']`)!;

                    const prevStyle = hex.style.getPropertyValue("--background-color");
                    const prevOverride = hex.style.getPropertyValue("--hex-choice-color-override");

                    setTimeout(() => {
                        hex.pulse();
                        hex.style.setProperty("--background-color", "var(--hex-bad-color)");
                        hex.style.removeProperty("--hex-choice-color-override");

                        setTimeout(() => {
                            hex.style.setProperty("--background-color", prevStyle);
                            hex.style.setProperty("--hex-choice-color-override", prevOverride);
                        }, this.TRANSITION_DURATION_FLASH);
                    }, 15 * index++); //TODO: move this to constant
                } else if (isValid) {
                    // if valid, flash greem
                    const hex: HexCell = this.shadowRoot!.querySelector(`hex-cell[row='${row}'][col='${col}']`)!;

                    setTimeout(() => {
                        hex.disabled = false;
                        hex.pulse();
                        hex.style.removeProperty("--hex-choice-color-override");
                        hex.style.setProperty("--background-color", "var(--hex-good-color)");
                    }, 15 * index++);
                }
            }
        }

        // set a timeout to state we're not checking
        const maxAnimationTime = 15 * index + this.TRANSITION_DURATION_FLASH + 100;
        setTimeout(() => {
            this.gameIsCheckingComplete = false;
        }, maxAnimationTime)

        // on success, do this
        if (isValid) {

            const shareUrl = this.getBoardUrl();

            this.userEventsServiceComposite.logEvent(
                boardCompletedEvent({
                    ...this.session.params,
                    gameDuration: completionTime
                })
            );

            // if daily board, log that event
            if (this.session.dailyPuzzleTimestamp) {
                this.userEventsServiceComposite.logEvent(
                    dailyBoardCompletedEvent({
                        ...this.session.params,
                        dayTimestamp: this.session.dailyPuzzleTimestamp,
                        gameDuration: completionTime
                    })
                );
            }

            this.gameComplete = true;
            // TODO: move this logic away from this component
            this.achievementService.registerGameCompleted(
                this.session.params.gameSize,
                this.session.params.difficulty
            );
            this.storageService.set(this.sessionKey, this.createSessionParams());

            setTimeout(() => {
                //this.showPuzzleCompleteModal(params, shareUrl, completionTime);
                this.dispatchEvent(new CustomEvent('game-complete', {
                    detail: {
                        session: this.session,
                        gameDuration: completionTime,
                        boardUrl: shareUrl
                    }
                }));
            }, maxAnimationTime);
        } else {
            // before dispatching, check if it shte same as the previous emission
            let diffFound = false;
            if (this.lastSubmitBoardState !== null && this.lastSubmitBoardState.gameSize === this.session.boardState?.gameSize) {
                for (let row=0; !diffFound && row<this.session.boardState.cells.length; row++) {
                    for (let col=0; !diffFound && col<this.session.boardState.cells[row].length; col++) {
                        const valA = this.session.boardState.cells[row][col];
                        const valB = this.lastSubmitBoardState.cells[row][col];
                        if (valA !== valB) {
                            diffFound = true;
                        }
                    }
                }
            }
            this.lastSubmitBoardState = JSON.parse(JSON.stringify(this.session.boardState));

            if (diffFound) {
                this.userEventsServiceComposite.logEvent(
                    boardWrongSubmissionEvent({
                        ...this.session.params,
                        gameDuration: completionTime
                    })
                );

                // if daily board, log that event
                if (this.session.dailyPuzzleTimestamp) {
                    this.userEventsServiceComposite.logEvent(
                        dailyBoardWrongSubmissionEvent({
                            ...this.session.params,
                            dayTimestamp: this.session.dailyPuzzleTimestamp,
                            gameDuration: completionTime
                        })
                    );
                }
            }
        }
    }

    private onCellRightClick(
        e: Event,
        row: number,
        col: number
    ): void {
        e.preventDefault();

        if (this.mode === 'display' || this.disabledCells[row].has(col) || this.gameComplete) {
            return;
        }

        if (this.unifiedHexModalEnabled) {
            this.onCellClick(e, this.session.boardState!.cells[row][col], row, col);
            return;
        }

        const hexCell = e.currentTarget! as HexCell;
        const prevOverride = hexCell.style.getPropertyValue("--hex-choice-color-override");
        hexCell.style.setProperty("--hex-choice-color-override", "var(--hex-hover-color)");

        const cellColorModalLayover = new ModalLayover();
        const { template, onDisplay } = this.getHexColorModalTemplate(e, row, col, cellColorModalLayover, true);

        // delete if existing one
        const modalId = `${this.id}-cell-color-modal`;
        const existing = this.shadowRoot!.querySelector(`#${modalId}`);
        if (existing) {
            this.shadowRoot!.removeChild(existing);
        }

        // Set the template to modal-layover's content
        cellColorModalLayover.id = modalId;
        cellColorModalLayover.contentTemplate = template;
        cellColorModalLayover.title = "Select a Color";

        this.shadowRoot!.appendChild(cellColorModalLayover);
        cellColorModalLayover.show();

        onDisplay(cellColorModalLayover);
        /*cellColorModalLayover.addEventListener("modal-hide", () => {
            console.debug(`[HexakaiBoard] right click menu closed, clearing color override`);
            hexCell.style.setProperty("--hex-choice-color-override", prevOverride);
        }, { once: true });*/
    }

    /**
     * If left and right are both clicked, clear the cell
     */
    private onCellLeftRightClick(
        e: Event,
        row: number,
        col: number
    ): void {
        e.preventDefault();

        const hexElement = e.target as HexCell;

        this.onCellColorSelected(hexElement, 0, row, col, true);
        this.onCellValueSelected(hexElement, '', row, col);
    }

    private togglePencilMarking(
        event: any,
        row: number,
        col: number,
        value: string,
        alterStack = true
    ): void {
        const existingIndex = this.session.pencilMarks![row][col].indexOf(value);
        const hex: HexCell = this.shadowRoot!.querySelector(`hex-cell[row='${row}'][col='${col}']`)!;

        if (existingIndex > -1) {
            this.session.pencilMarks![row][col].splice(existingIndex, 1);
            event?.target.classList.remove("modal-value-selected");
            hex.removePencil(value);
        } else {
            this.session.pencilMarks![row][col] =
                [...new Set([value, ...this.session.pencilMarks![row][col]])];
            event?.target.classList.add("modal-value-selected");
            hex.addPencil(value);
        }

        this.markBoardActionTaken();

        hex.pulse();

        if (alterStack) {
            this.session.undoRedo!.redoStack = [];
            this.session.undoRedo!.undoStack.push({
                type: HexakaiGameActionType.pencil,
                oldValue: existingIndex === -1 ? '' : value,
                newValue: existingIndex > -1 ? '' : value,
                row,
                col
            });
        }

        this.storageService.set(this.sessionKey, this.session);
    }

    private onCellColorSelected(
        hexElement: HexCell,
        colorNumber: number,
        row: number,
        col: number,
        applyCss: boolean
    ): void {
        console.log("[HexakaiBoard] cell color selected", { row, col, colorNumber })

        const oldValue = this.session.cellColors![row][col];

        // if this is a no-op, exit early
        if (oldValue === colorNumber) {
            return;
        }

        this.setCellColor(hexElement, colorNumber, row, col, applyCss);

        // mark this as the start of the board if undo/redo is empty
        this.markBoardActionTaken();

        this.session.undoRedo!.redoStack = [];
        this.session.undoRedo!.undoStack.push({
            type: HexakaiGameActionType.color,
            oldValue: `${oldValue}`,
            newValue: `${colorNumber}`,
            row,
            col
        });

        this.storageService.set(this.sessionKey, this.session);
    }

    /**
     * This sets the cell color and flashes the cell without updating the session
     */
    private setCellColor(
        hexElement: HexCell,
        colorNumber: number,
        row: number,
        col: number,
        applyCss: boolean
    ): void {
        console.log("[HexakaiBoard] setting cell color", { row, col, colorNumber });

        if (applyCss) {
            if (colorNumber > 0) {
                hexElement.style.setProperty("--hex-choice-color-override", `var(--color-note-${colorNumber})`);
            } else {
                hexElement.style.removeProperty("--hex-choice-color-override");
            }
        }

        hexElement.pulse();
        this.session.cellColors![row][col] = colorNumber;
    }

    private resetGame(): void {
        console.log("[HexakaiBoard] resetting game");

        let index = 0;
        for (let row = 0; row < this.boardData.getNumRows(); row++) {
            for (let col = 0; col < this.boardData.getNumCols(row); col++) {
                if (!this.disabledCells[row].has(col)) {
                    this.boardAssignment.cells[row][col] = "";
                    this.session.boardState!.cells[row][col] = "";
                    this.session.cellColors![row][col] = 0;
                    this.session.pencilMarks![row][col] = [];

                    const hex: HexCell = this.shadowRoot!.querySelector(`hex-cell[row='${row}'][col='${col}']`)!;

                    const prevStyle = hex.style.getPropertyValue("--background-color");
                    setTimeout(() => {
                        hex.setAttribute('value', '');
                        hex.style.removeProperty("--hex-choice-color-override");
                        hex.pulse();
                        hex.style.setProperty("--background-color", "var(--hex-neutral-color)");
                        setTimeout(() => {
                            hex.style.setProperty("--background-color", prevStyle);
                        }, this.TRANSITION_DURATION_FLASH);
                    }, 15 * index++); //TODO: move this to constant
                }
            }
        }

        this.session.undoRedo!.redoStack = [];
        this.session.undoRedo!.undoStack = [];
        this.storageService.set(this.sessionKey, this.session);
    }

    private onResetClick(): void {
        console.log("[HexakaiBoard] reset clicked");
        if (this.gameComplete) {
            return;
        }

        const modalID = this.id + "-reset-game";
        const existingModal = document.querySelector(`#${modalID}`);
        if (existingModal) {
            document.body.removeChild(existingModal);
        }

        // add settings to modal element
        const modalElement = new ModalLayover();
        modalElement.id = modalID;
        modalElement.title = "Reset Game";

        // handle actions
        const closeModal = () => document.body.removeChild(modalElement);
        const reset = () => {
            closeModal();
            this.resetGame();
            this.requestUpdate();
        }
        modalElement.contentTemplate = html`
            <style>
                .modal-choices {
                    margin-top: 10px;
                    display: flex;
                }

                styled-button {
                    margin: 7px;
                }
            </style>
            <p>Are you sure you want to reset the game? All progress will be lost.</p>
            <div class="modal-choices">
                <styled-button @click=${reset}>Reset</styled-button>
                <styled-button @click=${closeModal}>Cancel</styled-button>
            </div>
        `;

        document.body.appendChild(modalElement);
        modalElement.show();
    }

    /**
     * When the user requests a new game, show the modal for options
     * and make once they submit
     */
    private onNewGameClick(): void {
        console.log("[HexakaiBoard] new game clicked");

        const modalID = this.id + "-new-game";
        const existingModal = document.querySelector(`#${modalID}`);
        if (existingModal) {
            document.body.removeChild(existingModal);
        }

        // add settings to modal element
        const modalElement = new ModalLayover();
        modalElement.id = modalID;
        modalElement.title = "New Game";
        modalElement.show();

        document.body.appendChild(modalElement);

        // handle actions
        const closeModal = () => document.body.removeChild(modalElement);
        const createGame = async (hold: boolean) => {

            const settingsEl = document.querySelector(`#${modalID}`)?.querySelector("hexakai-game-settings") as HexakaiGameSettings;
            this.gameSettings = settingsEl!.getSettings();
            closeModal();

            if (!this.gameComplete && this.session.boardStartTime
                && (this.session.undoRedo!.undoStack.length !== 0
                    || this.session.undoRedo!.redoStack.length !== 0)) {
                const completionTime = this.session.boardStartTime
                    ? ((+new Date()) - this.session.boardStartTime)
                    : 0;

                this.userEventsServiceComposite.logEvent(
                    boardAbandonedEvent({
                        ...this.session.params,
                        gameDuration: completionTime
                    })
                );

                if (this.session.dailyPuzzleTimestamp) {
                    this.userEventsServiceComposite.logEvent(
                        dailyBoardAbandonedEvent({
                            ...this.session.params,
                            gameDuration: completionTime,
                            dayTimestamp: this.session.dailyPuzzleTimestamp
                        })
                    );
                }
            }

            await this.storageService.set(this.sessionKey, this.createSessionParams());

            if (hold) {
                // don't generate the board until enter is clicked
                const existingStyle = this.style.visibility;
                this.style.visibility = "hidden";
                await new Promise<void>(resolve => {
                    const listener = document.addEventListener('keydown', (e: any) => {
                        if (e.code === "Space" || e.code === "Enter" || e.code === "NumpadEnter") {
                            document.removeEventListener('keydown', listener as any);
                            resolve();
                        }
                    });
                });
                this.style.visibility = existingStyle;
            }

            console.log("[HexakaiBoard] Updating settings and rendering new game", this.gameSettings);
            await this.initializeGame();
            await this.storageService.remove(this.hintKey);
        };

        modalElement.contentTemplate = html`
            <style>
                .modal-choices {
                    margin-top: 10px;
                    display: flex;
                }

                styled-button {
                    margin: 7px;
                }

                hexakai-game-settings {
                    width: 100%;
                    height: 100%;
                    max-height: 100%;
                    overflow: auto;
                }
            </style>
            <hexakai-game-settings settings=${JSON.stringify(this.gameSettings)}></hexakai-game-settings>
            <div class="modal-choices">
                <styled-button id="game-start-play" @click="${() => createGame(false)}" @contextmenu="${(e: any) => {
                e.preventDefault();
                createGame(true);
            }}">Play!</styled-button>
                <styled-button @click=${closeModal}>Cancel</styled-button>
            </div>
        `;
    }

    /**
     * Zoom in and out by changing the container's height.
     * The hexes inside will grow or shrink to accomadate, giving the
     * appearance of zoom
     */
    private zoom(zoomIn: boolean): boolean {
        let oldZoomAmount = this.zoomAmount;
        this.zoomAmount *= zoomIn ? 1.1 : 1 / 1.1;
        if (this.zoomAmount < 0.3) {
            this.zoomAmount = 0.3;
        }
        if (this.zoomAmount > 3) {
            this.zoomAmount = 3;
        }

        if (oldZoomAmount === this.zoomAmount) {
            return false;
        }

        this.storageService.set(this.zoomKey, { zoom: this.zoomAmount });
        console.log(`[HexakaiBoard] zoom invoked`, { zoomIn, amount: this.zoomAmount });
        this.requestUpdate();
        this.updateComplete.then(() => this.adjustBoardHeight());
        return true;
    }

    /**
     * Helpful for dragging functionality
     */
    private startDrag(e: MouseEvent): void {
        this.isDragging = true;
        this.scrollStartX = e.pageX - (this.shadowRoot!.querySelector('.game-board-container')! as HTMLElement).offsetLeft;
        this.scrollStartY = e.pageY - (this.shadowRoot!.querySelector('.game-board-container')! as HTMLElement).offsetTop;
        this.scrollScrollLeft = this.shadowRoot!.querySelector('.game-board-container')!.scrollLeft;
        this.scrollScrollTop = this.shadowRoot!.querySelector('.game-board-container')!.scrollTop;
    }

    private startTouchDrag(e: TouchEvent): void {
        if (e.touches.length === 2) {
            // Pinch gesture starts
            this.initialPinchDistance = this.calculatePinchDistance(e.touches[0], e.touches[1]);
            this.initialPinchZoomAmount = this.zoomAmount;
        } else if (e.touches.length === 1) {
            // Single touch starts dragging
            this.isDragging = false;
            this.scrollStartX = e.touches[0].clientX;
            this.scrollStartY = e.touches[0].clientY;
            this.scrollScrollLeft = this.shadowRoot!.querySelector('.game-board-container')!.scrollLeft;
            this.scrollScrollTop = this.shadowRoot!.querySelector('.game-board-container')!.scrollTop;
        }
    }

    /**
     * For multi-gesture zooming, calculate the distance between touches
     */
    private calculatePinchDistance(touch1: Touch, touch2: Touch): number {
        const xDiff = touch2.clientX - touch1.clientX;
        const yDiff = touch2.clientY - touch1.clientY;
        return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
    }

    private handleTouchDrag(e: TouchEvent): void {
        if (e.touches.length === 2) {
            // Pinch gesture in progress
            e.preventDefault();
            const newPinchDistance = this.calculatePinchDistance(e.touches[0], e.touches[1]);
            const scale = newPinchDistance / this.initialPinchDistance;
            this.zoomAmount = this.initialPinchZoomAmount * scale;

            if (this.zoomAmount < 0.3) {
                this.zoomAmount = 0.3;
            }
            if (this.zoomAmount > 3) {
                this.zoomAmount = 3;
            }

            this.storageService.set(this.zoomKey, { zoom: this.zoomAmount });
            this.requestUpdate();
        } else if (e.touches.length === 1 && !this.isDragging) {
            const moveX = Math.abs(e.touches[0].clientX - this.scrollStartX);
            const moveY = Math.abs(e.touches[0].clientY - this.scrollStartY);
            if (moveX < 10 && moveY < 10) return;
            this.isDragging = true;
        }

        if (this.isDragging) {
            e.preventDefault();
            const container = this.shadowRoot!.querySelector('.game-board-container')! as HTMLElement;
            const currentX = e.touches[0].clientX;
            const currentY = e.touches[0].clientY;
            const diffX = currentX - this.scrollStartX;
            const diffY = currentY - this.scrollStartY;
            container.scrollLeft = this.scrollScrollLeft - diffX;
            container.scrollTop = this.scrollScrollTop - diffY;
        }
    }

    /**
     * Helpful for dragging functionality
     */
    private stopDrag(): void {
        this.isDragging = false;
        this.initialPinchDistance = 0;
        this.initialPinchZoomAmount = 1;
    }

    /**
     * Helpful for dragging functionality
     */
    private handleDrag(e: MouseEvent): void {
        if (!this.isDragging) {
            return;
        }
        e.preventDefault();  // prevent selecting text or other draggable elements
        const x = e.pageX - (this.shadowRoot!.querySelector('.game-board-container')! as HTMLElement).offsetLeft;
        const y = e.pageY - (this.shadowRoot!.querySelector('.game-board-container')! as HTMLElement).offsetTop;
        const walkX = (x - this.scrollStartX) * 2; // Multiply by 2 or other factor to adjust sensitivity
        const walkY = (y - this.scrollStartY) * 2; // Same here for vertical scroll
        this.shadowRoot!.querySelector('.game-board-container')!.scrollLeft = this.scrollScrollLeft - walkX;
        this.shadowRoot!.querySelector('.game-board-container')!.scrollTop = this.scrollScrollTop - walkY;
    }

    private onUndoClick(): void {
        if (!this.session.undoRedo || this.session.undoRedo.undoStack.length === 0) {
            return;
        }

        const action = this.session.undoRedo.undoStack.pop()!;
        this.session.undoRedo.redoStack.push(action);

        const element = this.shadowRoot!.querySelector(`hex-cell[row='${action.row}'][col='${action.col}']`) as HexCell;

        switch (action.type) {
            case HexakaiGameActionType.value:
                this.setCellValue(element, action.oldValue, action.row, action.col);
                break;
            case HexakaiGameActionType.color:
                this.setCellColor(element, parseInt(action.oldValue), action.row, action.col, true);
                break;
            case HexakaiGameActionType.pencil:
                if (action.newValue === '') {
                    this.togglePencilMarking(null, action.row, action.col, action.oldValue, false);
                } else {
                    this.togglePencilMarking(null, action.row, action.col, action.newValue, false);
                }
                break;
        }

        this.storageService.set(this.sessionKey, this.session);
    }

    private onRedoClick(): void {
        if (!this.session.undoRedo || this.session.undoRedo.redoStack.length === 0) {
            return;
        }

        const action = this.session.undoRedo.redoStack.pop()!;
        this.session.undoRedo.undoStack.push(action);

        const element = this.shadowRoot!.querySelector(`hex-cell[row='${action.row}'][col='${action.col}']`) as HexCell;

        switch (action.type) {
            case HexakaiGameActionType.value:
                this.setCellValue(element, action.newValue, action.row, action.col);
                break;
            case HexakaiGameActionType.color:
                this.setCellColor(element, parseInt(action.newValue), action.row, action.col, true);
                break;
            case HexakaiGameActionType.pencil:
                if (action.newValue === '') {
                    this.togglePencilMarking(null, action.row, action.col, action.oldValue, false);
                } else {
                    this.togglePencilMarking(null, action.row, action.col, action.newValue, false);
                }
                break;
        }

        this.storageService.set(this.sessionKey, this.session);
    }

    /**
     * If on the correct client (checked above), we can call the window
     * to auto-fill the board
     */
    private fillSolution(value?: string): void {
        if (!this.solutionAssignment) {
            return;
        }

        if (value) {
            value = `${value}`;
        }

        for (let row = 0; row < this.solutionAssignment.cells.length; row++) {
            for (let col = 0; col < this.solutionAssignment.cells[row].length; col++) {
                if (!value || this.solutionAssignment.cells[row][col] === value) {
                    const hexCell = this.shadowRoot!.querySelector(`hex-cell[row='${row}'][col='${col}']`) as HexCell;
                    this.onCellValueSelected(
                        hexCell,
                        this.solutionAssignment.cells[row][col],
                        row,
                        col
                    );
                }
            }
        }
    }

    /**
     * For internal purposes, update the entire board (effectively taking it out of the game)
     */
    private setCustomBoardContents(contents: string): void {
        const items = contents.split(",");
        let index = 0;

        let sum = 0;
        for (let row = 0; row < this.boardData.getNumRows(); row++) {
            for (let col = 0; col < this.boardData.getNumCols(row); col++) {
                const hexCell = this.shadowRoot!.querySelector(`hex-cell[row='${row}'][col='${col}']`) as HexCell;
                hexCell.removeAttribute("disabled");
                this.onCellValueSelected(
                    hexCell,
                    items[index],
                    row,
                    col
                );
                sum += parseInt(items[index]);
                index++;
            }
        }
    }

    /**
     * Given the current board state, find all cells that can have a possible value
     */
    private colorPossibleValues(value: string, colorNumber = 1): void {
        for (let row = 0; row < this.boardData.getNumRows(); row++) {
            for (let col = 0; col < this.boardData.getNumCols(row); col++) {
                // use the row and col visitor to check if any other cell assignments 
                let isPossible = true;
                for (const [vValue] of this.boardVisitor.visitRowForward(row, 0, false)) {
                    if (value === vValue) {
                        isPossible = false;
                        break;
                    }
                }

                if (isPossible) {
                    for (const [vValue] of this.boardVisitor.visitColLeft(row, col)) {
                        if (value === vValue) {
                            isPossible = false;
                            break;
                        }
                    }
                }

                if (isPossible) {
                    for (const [vValue] of this.boardVisitor.visitColRight(row, col)) {
                        if (value === vValue) {
                            isPossible = false;
                            break;
                        }
                    }
                }

                if (isPossible) {
                    const hexCell = this.shadowRoot!.querySelector(`hex-cell[row='${row}'][col='${col}']`) as HexCell;
                    this.onCellColorSelected(
                        hexCell,
                        colorNumber,
                        row,
                        col,
                        true
                    );
                }
            }
        }
    }

    /**
     * Create a game session using defaults
     */
    private createSessionParams(): HexakaiGameSession {
        const params = this.gameSettings || this.DEFAULT_GAME_SETTINGS;

        console.log("[HexakaiBoard] game params", params);

        return {
            params
        };
    }

    private onShareClick(): void {
        const url = this.getBoardUrl();

        console.log("[HexakaiBoard] share clicked", url);


        this.shareService.share(
            "Hexakai",
            `Here's a game of Hexakai on a board of size ${this.session.params.gameSize} rated at ${this.session.params.difficulty} difficulty:`,
            url,
            "Share this Board"
        );
    }

    /**
     * Try to start the game.
     * If the user started a game, show them a warning first.
     * Silently fail if the user cancels the operation
     */
    private async tryUpdateSessionFromIncoming(serialized: any): Promise<void> {
        return new Promise(resolve => {
            const newSession = typeof serialized === 'string'
                ? HexakaiGameSessionSerializer.deserialize(serialized)
                : serialized;

            const loadGame = async () => {
                // check if this is params vs actual session
                if (newSession.difficulty && newSession.gameSize) {
                    console.log(`[HexakaiBoard] initializing from params`, newSession);
                    this.gameSettings = newSession;
                    await this.storageService.remove(this.sessionKey);
                } else {
                    // if proceeding, update the state and call the initialize
                    console.log(`[HexakaiBoard] initializing from session`, newSession);
                    await this.storageService.set(this.sessionKey, newSession);
                }
                resolve();
            }

            if (this.hasGameStarted()) {
                if (!(newSession.difficulty && newSession.gameSize)) {
                    // check if the current game is the same or not
                    const newParams = newSession.params;
                    const thisParams = this.session.params;

                    // TODO: move comparison to utility
                    const paramsEqual = newParams.difficulty === thisParams.difficulty
                        && newParams.gameSize === thisParams.gameSize;

                    // check if cells are the same
                    const newDisabled = newSession.disabledCells;
                    const thisDisabled = this.session!.disabledCells;

                    const disabledCellsEqual = JSON.stringify(newDisabled) === JSON.stringify(thisDisabled);

                    // if we're already in the same game, ignore
                    if (paramsEqual && disabledCellsEqual) {
                        resolve();
                        return;
                    }
                }

                this.showNewGameConfirmation(() => {
                    loadGame();
                    const completionTime = this.session.boardStartTime
                        ? ((+new Date()) - this.session.boardStartTime)
                        : 0;
                    this.userEventsServiceComposite.logEvent(
                        boardAbandonedEvent({
                            ...this.session.params,
                            gameDuration: completionTime
                        })
                    );

                    if (this.session.dailyPuzzleTimestamp) {
                        this.userEventsServiceComposite.logEvent(
                            dailyBoardAbandonedEvent({
                                ...this.session.params,
                                gameDuration: completionTime,
                                dayTimestamp: this.session.dailyPuzzleTimestamp
                            })
                        );
                    }
                }, () => {
                    resolve();
                });
            } else {
                loadGame();
            }
        });
    }

    /**
     * Determine if the user's started the game by checking the undo redo stacks
     */
    private hasGameStarted(): boolean {
        if (!this.session?.undoRedo) {
            return false;
        }

        return this.session.undoRedo.undoStack.length > 0
            || this.session.undoRedo.redoStack.length > 0;
    }

    private async onHintClick(): Promise<void> {
        console.log(`[HexakaiBoard] hint clicked`);
        const hintTimestamp = await this.storageService.get(this.hintKey) as any;
        if (!hintTimestamp || hintTimestamp.timestamp < +new Date() - this.hintDuration) {
            this.showHint();
        } else {
            this.showHintNotYet(hintTimestamp.timestamp);
        }
    }

    private showHintNotYet(hintTimestamp: number): void {
        let remainingInterval: any;

        const modalId = 'hint';
        const modalTitle = "Hint";
        const contentTemplate = html`
            <style>
                .container {
                    display: flex;
                    text-align: center;
                    align-items: center;
                }

                .buttons-row {
                    display: flex;
                    width: 100%;
                    justify-content: center;

                    flex-wrap: wrap;
                }

                .buttons-row styled-button {
                    margin: 10px;                   
                }

                p {
                    margin-top: 0;
                    margin-bottom: 0;
                }

                @media screen and (max-width: 350px) {   
                    .buttons-row styled-button {
                        margin: 5px;                   
                    }
                }
            </style>
            <div class="container">
                <p>You've just looked at a hint. You can't look at another hint for
                    <span class="hint-duration-remaining">
                        ${formatDuration(hintTimestamp + this.hintDuration - +new Date())}.
                    </span>
                </p>
            </div>

            <br />
            <div class="buttons-row">
                <styled-button @click=${() => {
                clearInterval(remainingInterval);
                this.modalService.hideModal(modalId)
            }}>Close</styled-button>
            </div>
        `;
        this.modalService.showModal(
            modalId,
            modalTitle,
            contentTemplate
        );

        remainingInterval = setInterval(() => {
            const dRemaining = hintTimestamp + this.hintDuration - +new Date();
            if (dRemaining < 0) {
                this.modalService.hideModal(modalId);
                this.showHint();
                clearInterval(remainingInterval);
            } else {
                document.querySelector(".hint-duration-remaining")!
                    .innerHTML = formatDuration(dRemaining) + ".";
            }
        }, 1_000);
    }

    private async showHint(): Promise<void> {
        this.userEventsServiceComposite.logEvent(
            hintClickedEvent(this.session.params)
        );

        const hints = await this.hintFinder.getHints(this.session);

        // select a hint at random
        const hint = hints[Math.floor(getRandom() * hints.length)];
        console.log(`[HexakaiBoard] showing hint`, hint);
        let valueRangeString = "";
        if (hint) {
            // highlight the cell
            for (let row = 0; row < this.boardData.getNumRows(); row++) {
                for (let col = 0; col < this.boardData.getNumCols(row); col++) {
                    const hex: HexCell = this.shadowRoot!.querySelector(`hex-cell[row='${row}'][col='${col}']`)!;
                    if (row === hint.row && col === hint.col) {
                        hex.showSpecialFilter();
                    } else {
                        hex.hideSpecialFilter();
                    }
                }
            }

            // assumes the value range only gives the correct value
            const valueRange = [...new Set([
                ...hint.valueRange,
                ...shuffleToNew(this.boardData.getCellValues()).slice(0, 3)
            ])]
                .sort((a, b) => parseInt(a, 16) - parseInt(b, 16))
                .map(v => HexakaiCellValueMapper.getValue(v, this.hexCellType));

            for (let i = 0; i < valueRange.length; i++) {
                valueRangeString += valueRange[i];
                if (i < valueRange.length - 2) {
                    valueRangeString += ", ";
                } else if (i === valueRange.length - 2) {
                    valueRangeString += ", or ";
                }
            }
        }

        // show the modal
        const showAd = this.clientService.getConfig().body.gameCompleteAdVisible
            && !(await this.hexakaiPlusService.isFeatureUnlocked(HexakaiPlusFeatures.noAds));

        const adMarkup = showAd
            ? html`<hr style="width:95%"/>
                <p><b>A brief word from our sponsors:</b></p>
                <ad-unit .unit="${GoogleAdsProviderService.AD_UNITS.fixed400}"></ad-unit>
            `
            : '';

        const modalId = 'hint';
        const modalTitle = "Hint";
        const hintTemplate = !hint ? html`The board looks good as it is!` :
            hint.hintType === HexakaiGameHintType.wrongValue
                ? html`You've placed the wrong value on the cell we've just highlighted.`
                : html`For the cell we've just highlighted, the possible values are ${valueRangeString}.`;

        const contentTemplate = html`
            <style>
                .buttons-row {
                    display: flex;
                    width: 100%;
                    justify-content: center;

                    flex-wrap: wrap;
                }

                .buttons-row styled-button {
                    margin: 10px;                   
                }

                p {
                    margin-top: 0;
                    margin-bottom: 0;
                }

                @media screen and (max-width: 350px) {   
                    .buttons-row styled-button {
                        margin: 5px;                   
                    }
                }
            </style>
            <div class="container">
                <p>${hintTemplate}</p>
                <br/>
                <p>Memorize this before going back to the board, as this is the only time we'll show you this hint.</p>
            </div>

            <br />
            <div class="buttons-row">
                <styled-button @click=${() => this.modalService.hideModal(modalId)}>Close</styled-button>
            </div>
            ${adMarkup}
        `;
        this.modalService.showModal(
            modalId,
            modalTitle,
            contentTemplate
        );

        await this.storageService.set(this.hintKey, {
            timestamp: +new Date()
        });
    }

    private getBoardUrl(): string {
        let shareUrl: string;

        if (this.session.dailyPuzzleTimestamp) {
            shareUrl = this.clientService.createLocalLink(
                "app",
                {
                    d: DailyPuzzleService.getDateString(this.session.dailyPuzzleTimestamp)
                },
                false
            );
        } else {
            const serialized = HexakaiGameSessionSerializer.serialize(
                this.session,
                {
                    includeFilledColors: false,
                    includeFilledValues: false,
                    includePencilValues: false
                }
            );

            // make the new url
            shareUrl = this.clientService.createLocalLink(
                "app",
                {
                    b: serialized
                },
                false
            );
        }

        return shareUrl;
    }

    private showNewGameConfirmation(
        confirmCallback: Function,
        denyCallback: Function
    ): void {
        const id = "confirm-start-new-game-modal";

        const contentTemplate = html`
            <p>It looks like you have a game in progress. If you start this new game, you'll lose your progress in the current game.
                How would you like to proceed?</p>
            
            <div class="display: flex">
                <styled-button @click=${() => {
                confirmCallback();
                this.modalService.hideModal(id);
            }}>Start Game</styled-button>
                <styled-button @click=${() => {
                denyCallback();
                this.modalService.hideModal(id)
            }}>Cancel</styled-button>
            </div>
        `;

        this.modalService.showModal(
            id,
            "Start Game",
            contentTemplate
        );
    }

    private adjustBoardHeight(): void {
        const cellRowEl = this.shadowRoot!.querySelector(".hex-row")!;
        let rowHeight = 0;
        if (cellRowEl) {
            rowHeight = cellRowEl.getBoundingClientRect().height;
        }

        if (rowHeight === 0) {
            return;
        }

        const gameSize = this.session?.params
            ? this.session.params.gameSize
            : Math.sqrt(this.board.length);

        const numRows = gameSize * 2 - 1;
        const lastRowOffset = (numRows - 1) * 0.25;
        // +30 includes padding at the top & bottom
        const baseHeight = rowHeight * numRows - rowHeight * lastRowOffset// + 30;
        this.boardHeight = `calc(${baseHeight}px + var(--board-height-extension, 0px))`;

        //console.log("---", rowHeight, gameSize, lastRowOffset, this.boardHeight)

        const board = this.shadowRoot!.querySelector(".game-board")! as HTMLElement;
        if (board) {
            board.style.height = this.boardHeight;
        }
    }

    private markBoardActionTaken(): void {
        // mark this as the start of the board if undo/redo is empty
        if (this.session.undoRedo!.undoStack.length === 0 && this.session.undoRedo!.redoStack.length === 0) {
            this.userEventsServiceComposite.logEvent(
                boardStartedEvent(this.session.params)
            );

            if (this.session.dailyPuzzleTimestamp) {
                this.userEventsServiceComposite.logEvent(
                    dailyBoardStartedEvent({
                        ...this.session.params,
                        dayTimestamp: this.session.dailyPuzzleTimestamp
                    })
                );
            }
        }
    }
}