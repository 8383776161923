import { HexakaiBoardData } from "../../hexakai-board/hexakai-board-data";
import { HexakaiBoardStateVisitor } from "../../hexakai-board/hexakai-board-state-visitor";
import { HexakaiBoardState } from "../../models/hexakai-board-state";
import { HexakaiGameDifficulty } from "../../models/hexakai-game-params";

export class HexakaiUncertaintyMeasurementService {

    private static instance: HexakaiUncertaintyMeasurementService;

    constructor() {
        if (HexakaiUncertaintyMeasurementService.instance) {
            return HexakaiUncertaintyMeasurementService.instance;
        }

        HexakaiUncertaintyMeasurementService.instance = this;
    }

    determineUncertainty(board: HexakaiBoardState<string>): number {
        const boardStateVisitor = new HexakaiBoardStateVisitor<string>(board);
        const boardData = new HexakaiBoardData(board.gameSize);

        let divideWeight = 0;
        let score = 0;

        for (let row = 0; row < boardData.getNumRows(); row++) {
            for (let col = 0; col < boardData.getNumCols(row); col++) {

                const weight = boardStateVisitor.countNumEmptyCellsConstraining(row, col) + 1;

                divideWeight += weight;

                if (!!board.cells[row][col]) {
                    continue;
                }

                const distinctConstrainingValues = new Set<string>();

                // visit rows and diagonals, add to it
                for (const [value] of boardStateVisitor.visitRowForward(row, 0, false)) {
                    if (value) {
                        distinctConstrainingValues.add(value);
                    }
                }

                for (const [value] of boardStateVisitor.visitColLeft(row, col)) {
                    if (value) {
                        distinctConstrainingValues.add(value);
                    }
                }

                for (const [value] of boardStateVisitor.visitColRight(row, col)) {
                    if (value) {
                        distinctConstrainingValues.add(value);
                    }
                }

                const numFreeCells = board.gameSize - distinctConstrainingValues.size;
                score += numFreeCells * weight;
            }
        }

        return score / (board.gameSize * divideWeight);
    }

    getUncertaintyRange(n: number, diff: HexakaiGameDifficulty): [number, number] {
        return (this.uncertaintyRanges as any)[n][diff];
    }

    // TODO: move to config
    private uncertaintyRanges = {
        "1": {
            "easy": [0, 1],
            "medium": [0, 1],
            "difficult": [0, 1],
            "ultra difficult": [0, 1]
        },
        "3": {
            "easy": [0.035, 0.075],
            "medium": [0.098, 0.240],
            "difficult": [0.260, 0.325],
            "ultra difficult": [0.345, 0.600]
        },
        "5": {
            "easy": [0.013, 0.090],
            "medium": [0.100, 0.200],
            "difficult": [0.210, 0.290],
            "ultra difficult": [0.300, 0.600]
        },
        "7": {
            "easy": [0.013, 0.060],
            "medium": [0.070, 0.140],
            "difficult": [0.150, 0.230],
            "ultra difficult": [0.240, 0.600]
        },
        "8": {
            "easy": [0.012, 0.050],
            "medium": [0.063, 0.130],
            "difficult": [0.140, 0.210],
            "ultra difficult": [0.220, 0.600]
        },
        "9": {
            "easy": [0.011, 0.040],
            "medium": [0.051, 0.130],
            "difficult": [0.140, 0.210],
            "ultra difficult": [0.220, 0.600]
        },
        "10": {
            "easy": [0.011, 0.035],
            "medium": [0.061, 0.120],
            "difficult": [0.130, 0.220],
            "ultra difficult": [0.230, 0.600]
        },
        "11": {
            "easy": [0.010, 0.035],
            "medium": [0.054, 0.110],
            "difficult": [0.120, 0.200],
            "ultra difficult": [0.210, 0.575]
        },
        "12": {
            "easy": [0.010, 0.030],
            "medium": [0.058, 0.100],
            "difficult": [0.110, 0.190],
            "ultra difficult": [0.200, 0.550]
        },
        "13": {
            "easy": [0.010, 0.030],
            "medium": [0.053, 0.110],
            "difficult": [0.120, 0.170],
            "ultra difficult": [0.180, 0.525]
        },
        "14": {
            "easy": [0.009, 0.025],
            "medium": [0.035, 0.100],
            "difficult": [0.110, 0.180],
            "ultra difficult": [0.190, 0.500]
        },
        "15": {
            "easy": [0.009, 0.023],
            "medium": [0.033, 0.090],
            "difficult": [0.100, 0.150],
            "ultra difficult": [0.160, 0.475]
        },
        "16": {
            "easy": [0.008, 0.021],
            "medium": [0.031, 0.090],
            "difficult": [0.100, 0.150],
            "ultra difficult": [0.160, 0.450]
        }
    }
}