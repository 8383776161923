import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators";
import { HexakaiPlusFeatureDescriptions } from "../hexakai-plus-subscription/hexakai-plus-features";

@customElement('hexakai-plus-description')
export class HexakaiPlusDescriptionComponent extends LitElement {

    static styles = css`
        .plus-container {
            display: flex;
            flex-wrap: wrap;
            gap: 1em;
            justify-content: center;
        }

        .plus-option {
            border: 1px solid var(--body-color);
            border-radius: 1em;
            padding: 1em;

            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            box-sizing: border-box;

            width: 22em;
            height: 14em;
        }

        .plus-option p {
            text-align: center;
        }
    `;


    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    render() {
        return html`
            <style>
                ${HexakaiPlusDescriptionComponent.styles}
            </style>
            <p>Hexakai Plus is a subscription service that unlocks all of Hexakai's advanced features and removes all ads. Features include:</p>
            <div class="plus-container">
                ${Object.values(HexakaiPlusFeatureDescriptions).map(({title, description}) => {
                    return html`
                        <div class="plus-option">
                            <h3>${title}</h3>
                            <p>
                                ${description}
                            </p>
                        </div>
                    `;
                })}
            </div>
        `;
    }

}
