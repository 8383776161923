/**
 * This file is included in every page and includes only core functionality to render pages properly
 */

// import the theme service and get this working as quickly as possible
import { ConsoleInterceptor } from './console-logger/console-logger';
import { AdService } from './ads-service/ad-service';
import { PreloaderService } from './preloader-service/preloader-service';
import { GoogleAdsProviderService } from './ads-service/google/google-ads-service';
import BUILD from "./client-service/b.json";
import { BlogPostService } from './blog-post-service/blog-post-service';
import { GlobalAccessService } from './global-access-service/global-access-service';
import { SessionService } from './session-service/session-service';
import { UserEventsServiceComposite } from './user-events/user-events-service-composite';
import { EventListenerEventsService } from './user-events/event-listener-events-service';
import { UserAuthenticationService } from './user-authentication/user-authentication-service';
import { HexakaiPlusSubscriptionService } from './hexakai-plus-subscription/hexakai-plus-subscription-service';
import { HexakaiPlusFeatures } from './hexakai-plus-subscription/hexakai-plus-features';
import { ComponentDependencyTree } from './component-dependency-tree/component-dependency-tree';

// intercept the console
new ConsoleInterceptor(console);
console.log("B", BUILD.id);

const clientService = ComponentDependencyTree.clientService;
GlobalAccessService.getInstance().registerClientService(clientService);

const modalService = ComponentDependencyTree.modalService;
const storageService = ComponentDependencyTree.localStorageService;

// force kickoff the theme service (this is redundant now)
ComponentDependencyTree.themeService;

// bring in client service to handle urls
const adService = new AdService(
    clientService,
    ComponentDependencyTree.toastService,
    ComponentDependencyTree.userEventsServiceComposite,
    ComponentDependencyTree.modalService
);
adService.initialize();

// init session service (this is redundant now)
ComponentDependencyTree.sessionService;
const hexakaiPlusService = ComponentDependencyTree.hexakaiPlusSubscriptionService;

// preload resources
new PreloaderService().initializePreload();

// put google ad in footer if enabled
hexakaiPlusService.isFeatureUnlocked(HexakaiPlusFeatures.noAds).then(async unlocked => {
    if (unlocked) {
        return;
    }

    await clientService.resolveWhenDomInteractive();
    if (clientService.getConfig().footer.googleAdVisiblePercent >= Math.random() * 100) {
        console.log("[Core] footer ads enabled, so attaching");
        const attr = GoogleAdsProviderService.AD_UNITS.primaryHorizontal;

        // TODO: convert to lit or pass in ad markup as string
        console.log("loaded");
        const markup = GoogleAdsProviderService.getAdMarkup(attr, false);
        const adEl = document.getElementById("google-container");
        if (!adEl) {
            return;
        }

        adEl.innerHTML =
            `   <hr/>
                <h3 style="text-align: center">A word from our sponsors:</h3>
                ${markup}
            `;
        console.log("[Core] footer ads attached");

        // TODO: hacky, find better way of encapsulating this
        ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});

    }
});

// show blog post modal if needed
const blogPostService = new BlogPostService(
    storageService,
    modalService,
    clientService
);