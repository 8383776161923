import { html, LitElement } from "lit";
import { customElement } from "lit/decorators";
import { ClientService } from "../client-service/client-service";
import { HexakaiPlusSubscriptionService } from "../hexakai-plus-subscription/hexakai-plus-subscription-service";
import { LocalStorageService } from "../local-storage/local-storage-service";
import { IUserInfo } from "../models/user-info";
import { UserAuthenticationService } from "../user-authentication/user-authentication-service";
import { ComponentDependencyTree } from "../component-dependency-tree/component-dependency-tree";

@customElement('hexakai-plus-manage')
export class HexakaiPlusManageComponent extends LitElement {

    private clientService = ComponentDependencyTree.clientService;
    private userAuthenticationService = ComponentDependencyTree.userAuthenticationService;
    private hexakaiPlusService = ComponentDependencyTree.hexakaiPlusSubscriptionService;

    private isLoading = true;
    private isLoggedIn = false;
    private isSubscribed = false;
    private userInfo: IUserInfo | null = null
    private paywallUrl: string | null = null;

    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    connectedCallback(): void {
        super.connectedCallback();

        Promise.all([
            this.userAuthenticationService.isLoggedIn(),
            this.hexakaiPlusService.isSubscribed(),
            this.userAuthenticationService.getUserInfo(),
            this.userAuthenticationService.isLoggedIn().then(isLoggedIn => {
                return isLoggedIn ? this.hexakaiPlusService.getPaywallUrl() : ""
            })
        ]).then(([isLoggedIn, isSubscribed, userInfo, paywallUrl]) => {
            this.isLoading = false;
            this.isLoggedIn = isLoggedIn;
            this.isSubscribed = isSubscribed;
            this.userInfo = userInfo;
            this.paywallUrl = paywallUrl;

            this.requestUpdate();
        });
    }

    render() {
        if (this.isLoading) {
            return html``;
        }

        if (!this.isLoggedIn) {
            return this.renderLoggedOut();
        }

        if (!this.isSubscribed) {
            return this.renderNotSubscribed();
        }

        return this.renderSubscribed();
    }

    private renderLoggedOut() {
        const href = this.clientService.createLocalLink("account", {}, true);

        return html`
            <p>To subscribe to Hexakai Plus or manage your active subscription,
            please log in to <a href="${href}">your account</a>.
        `;
    }

    private renderNotSubscribed() {
        return html`
            <p>You're currently not subscribed to Hexakai Plus. To subscribe, visit
            <a href="${this.paywallUrl}" target="_blank">this page</a> to setup your subscription for only $4.99 per month.
        `;
    }

    private renderSubscribed() {
        const url = this.clientService.createLocalLink('contact', {}, true);

        return html`
            <p>
                Congratulations! You're currently subscribed to Hexakai Plus.
                To manage your subscription, find the manage subscription link provided to you in your subscription confirmation email,
                or <a href="${url}">contact us</a> if you have any questions.</p>
        `;
    }
}