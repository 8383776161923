import { ClientService } from "../client-service/client-service";
import { LocalStorageService } from "../local-storage/local-storage-service";
import { UserEvent } from "../models/analytics-event";
import { UserEventsService } from "../models/user-events-service";
import { EventListenerEventsService } from "./event-listener-events-service";
import { GoogleUserEventsService } from "./google-user-events-service";
import { HexakaiComUserEventService } from "./hexakai-com-user-event-service";

export class UserEventsServiceComposite implements UserEventsService {

    private services: UserEventsService[];

    constructor(
        private clientService: ClientService,
        private localStorageService: LocalStorageService
    ) {
        this.services = [
            new EventListenerEventsService(),
            new GoogleUserEventsService(clientService),
            new HexakaiComUserEventService(clientService, localStorageService)
        ];
    }

    async logEvent(event: UserEvent<any>): Promise<void> {
        const promises = [];

        for (const service of this.services) {
            // swallow exceptions
            promises.push(service.logEvent(event).catch(err => {
                console.warn(`[UserEventsServiceComposite] error logging in ${service}`, err);
            }));
        }

        await Promise.all(promises);
    } 
}