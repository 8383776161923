import { ClientService } from "../client-service/client-service";
import { LocalStorageService } from "../local-storage/local-storage-service";
import { UserEvent } from "../models/analytics-event";
import { boardAbandonedEvent } from "./events/board-abandoned-event";
import { boardCompletedEvent } from "./events/board-completed-event";
import { boardGeneratedEvent } from "./events/board-generated-event";
import { boardStartedEvent } from "./events/board-started-event";
import { boardWrongSubmissionEvent } from "./events/board-wrong-submission-event";
import { dailyBoardAbandonedEvent } from "./events/daily-board-abandoned-event";
import { dailyBoardCompletedEvent } from "./events/daily-board-completed-event";
import { dailyBoardStartedEvent } from "./events/daily-board-started-event";
import { dailyBoardWrongSubmissionEvent } from "./events/daily-board-wrong-submission-event";
import { hintClickedEvent } from "./events/hint-clicked-event";
import { tutorialFinishedEvent } from "./events/tutorial-finished-event";
import { tutorialIgnoredEvent } from "./events/tutorial-ignored-event";
import { tutorialRequestedEvent } from "./events/tutorial-requested-event";
import { tutorialStartedEvent } from "./events/tutorial-started-event";

export class HexakaiComUserEventService {
    private static EVENTS_API = "/api/log-event";
    private static EVENT_MAP = new Map([
        [boardStartedEvent.eventName, "board_actions"],
        [boardCompletedEvent.eventName, "board_actions"],
        [dailyBoardCompletedEvent.eventName, "board_actions"],
        [boardAbandonedEvent.eventName, "board_actions"],
        [dailyBoardStartedEvent.eventName, "board_actions"],
        [dailyBoardAbandonedEvent.eventName, "board_actions"],
        [boardWrongSubmissionEvent.eventName, "board_actions"],
        [dailyBoardWrongSubmissionEvent.eventName, "board_actions"],
        [hintClickedEvent.eventName, "board_actions"],

        [boardGeneratedEvent.eventName, "board_generation"],

        [tutorialStartedEvent.eventName, "tutorial_actions"],
        [tutorialFinishedEvent.eventName, "tutorial_actions"],
        [tutorialRequestedEvent.eventName, "tutorial_actions"],
        [tutorialIgnoredEvent.eventName, "tutorial_actions"]
    ]);

    private localStorageQueueKey = "hexakai-com-logs-queue";
    private logsAreQueued = false;

    constructor(
        private clientService: ClientService,
        private localStorageService: LocalStorageService
    ) {
        if (navigator.onLine) {
            this.processLogQueue();
        }
    }

    async logEvent(event: UserEvent<any>): Promise<void> {
        const eventName = HexakaiComUserEventService.EVENT_MAP.get(event.eventName);
        if (!eventName) {
            console.log(`[HexakaiComUserEventService] no event found for event:`, event);
            return;
        }

        // log event
        const request = {
            event: eventName,
            data: this.transformData(event)
        }
        const doLog = this.clientService.getConfig().analytics.logEvents;
        console.log(`[HexakaiComUserEventService] ${
            doLog ? "logging" : "simulating logging of"
        } event`, request);

        if (!doLog) {
            return;
        }

        return this.sendLog(request);
    }

    private async sendLog(request: any): Promise<void> {
        try {
            const res = await fetch(HexakaiComUserEventService.EVENTS_API, {
                method: "post",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(request)
            });
            console.debug(`[AwsUserEventsService] response`, {
                request,
                res: await res.json()
            });
        } catch (err) {
            if (navigator.onLine) {
                throw err;
            }

            // if offline, queue it up
            await this.addLogToQueue(request);
            if (!this.logsAreQueued) {
                this.logsAreQueued = true;
                window.addEventListener('online', () => {
                    this.processLogQueue();
                }, {once: true});
            }
        }
    }

    private async addLogToQueue(log: any): Promise<void> {
        let queue = await this.localStorageService.get<any>(this.localStorageQueueKey);
        if (!queue) {
            queue = {
                queue: []
            };
        }

        queue.queue.push(log);
        await this.localStorageService.set(this.localStorageQueueKey, queue);
    }

    private async processLogQueue(): Promise<void> {
        const queue = await this.localStorageService.get<any>(this.localStorageQueueKey);
        if (!queue || queue.queue.length === 0) {
            return
        }

        // empty now, new will be added back if internet connection is lost again
        await this.localStorageService.set(this.localStorageQueueKey, {queue:[]});
        for (const log of queue.queue) {
            // send them all at once, don't wait for one another
            this.sendLog(log);
        }
    }

    private transformData(
        event: UserEvent<any>
    ): any {
        const data = {
            time: +new Date(),
            client: this.clientService.getClient(),
            ...event.data
        };

        switch (event.eventName) {
            case boardStartedEvent.eventName:
            case boardCompletedEvent.eventName:
            case dailyBoardCompletedEvent.eventName:
            case boardAbandonedEvent.eventName:
            case dailyBoardStartedEvent.eventName:
            case dailyBoardAbandonedEvent.eventName:
            case boardWrongSubmissionEvent.eventName:
            case dailyBoardWrongSubmissionEvent.eventName:
            case hintClickedEvent.eventName:
                data.boardEvent = event.eventName;
                break; 
            case tutorialStartedEvent.eventName:
            case tutorialFinishedEvent.eventName:
            case tutorialRequestedEvent.eventName:
            case tutorialIgnoredEvent.eventName:
                data.tutorialEvent = event.eventName;
                break;
        }

        return data;
    }
}