import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { DailyPuzzleService } from '../daily-puzzle/daily-puzzle-service';
import { HexakaiGameBoardCreatorWebWorker } from '../hexakai-game/hexakai-game-board-creator-webworker';
import { HexakaiGameSession } from '../models/hexakai-game-session';
import { GameAchievementService } from '../achievements/game-achievement-service';
import { HexakaiGameSessionSerializer } from '../hexakai-game-session/hexakai-game-session-serializer';
import { ClientService } from '../client-service/client-service';
import { ModalService } from '../modal-service/modal-service';
import { DIFFICULTY_TEXTS } from '../app-texts/difficulty-texts';
import { YouTubeService } from '../youtube-service.ts/youtube-service';
import { CellValueType, Settings } from '../models/settings';
import { SettingsService } from '../settings-service/settings-service';
import { LocalStorageService } from '../local-storage/local-storage-service';
import { HexakaiCellValueMapper } from '../hexakai-board/hexakai-cell-value-mapper';
import { UserEventsServiceComposite } from '../user-events/user-events-service-composite';
import { UserAuthenticationService } from '../user-authentication/user-authentication-service';
import { HexakaiPlusSubscriptionService } from '../hexakai-plus-subscription/hexakai-plus-subscription-service';
import { HexakaiPlusFeatures } from '../hexakai-plus-subscription/hexakai-plus-features';
import { HexakaiPlusDailyPuzzleService } from '../daily-puzzle/daily-puzzle-hexakai-plus-service';
import { GameAchievement } from '../models/game-achievement';
import { HexakaiGameParams } from '../models/hexakai-game-params';
import { ComponentDependencyTree } from '../component-dependency-tree/component-dependency-tree';

interface RenderTemplate {
    ach: GameAchievement;
    ts: {
        ts: number;
        no: number;
    };
    p: HexakaiGameParams;
    v: string;
    locked: boolean;
}

@customElement('daily-puzzles-view')
export class DailyPuzzlesView extends LitElement {

    private NUM_PUZZLES_IN_VIEW = 8;
    // store this, so if user waits until the next day, no bugs happen
    private startTopTimestamp = DailyPuzzleService.getStartOfDayTimestamp();

    private currentTopTimestamp = DailyPuzzleService.getStartOfDayTimestamp();
    private allPuzzlesUnlocked = false;
    private plusHref = "";

    private timestamps: {
        ts: number;
        no: number;
    }[] = [];
    private isEnd = false;
    private isStart = false;

    private renderTemplates: RenderTemplate[] = [];

    private clientService = ComponentDependencyTree.clientService;
    private modalService =ComponentDependencyTree.modalService;

    // user settings
    private settingsService = ComponentDependencyTree.settingsService;
    private dailyPuzzleService = ComponentDependencyTree.dailyPuzzleService;
    private hexakaiPlusService = ComponentDependencyTree.hexakaiPlusSubscriptionService;
    private hexakaiPlusDailyPuzzleService = ComponentDependencyTree.hexakaiPlusDailyPuzzleService;

    private hexCellType!: CellValueType;

    static styles = css`
        .session-info-container {
            position: relative;
            border: 1px solid var(--body-color);
            border-radius: 10px;
            padding: 1em;
            cursor: pointer;
            margin-bottom: 1em;
        }

        .session-info-container:hover {
            background-color: var(--input-border-color);
        }

        .session-info {
            display: flex;
            text-align: left;
            padding-left: 5%;
            padding-top: 1em;
            padding-bottom: 1em;
        }

        .session-icon {
            width: 8em;
            aspect-ratio: 1;
            margin-right: 1em;
        }

        .info-icon {
            position: absolute;
            right: 10px;
            top: 10px;
            width: 1.8em;
            z-index: 1;
        }

        .session-buttons styled-button {
            margin-top: 0.25em;
            margin-left: 0.125em;
            margin-right: 0.125em;
        }

        .info-locked h1, .info-locked h2, .info-locked h3, .info-locked p, .info-locked styled-button {
            filter: blur(2px);
        }

        .info-locked {
            filter: grayscale(100%) blur(1px);
        }
    `;

    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    connectedCallback(): void {
        super.connectedCallback();
        this.settingsService.onSettingsUpdated((settings: Settings) => {
            console.log(`[HexakaiBoard] settings update received`, settings);
            this.hexCellType = settings.cellValueType;
            this.requestUpdate();
        });
        this.hexCellType = CellValueType.standard;
        

        Promise.all([
            this.settingsService.getSettings(),
            this.hexakaiPlusService.isFeatureUnlocked(HexakaiPlusFeatures.allDailyPuzzles)
        ]).then(([settings, isUnlocked]) => {
            this.hexCellType = settings.cellValueType;
            this.allPuzzlesUnlocked = isUnlocked;
            this.plusHref = this.clientService.createLocalLink("plus", {}, true);

            this.loadPuzzlesAndRender();
        })
    }

    private async loadPuzzlesAndRender(): Promise<void> {
        const { timestamps, isEnd } = DailyPuzzleService.getPuzzleTimestamps(
            this.currentTopTimestamp,
            this.NUM_PUZZLES_IN_VIEW
        );

        this.timestamps = timestamps;
        this.isEnd = isEnd;
        this.isStart = timestamps[0].ts === this.startTopTimestamp;

        this.renderTemplates = [];
        for (let i = 0; i < this.timestamps.length; i++) {
            const ts = this.timestamps[i];
            const params = this.dailyPuzzleService.getPuzzleParams(ts.ts); 
            
            const ach = GameAchievementService.getAchievementFromGame(
                params.gameSize,
                params.difficulty
            );

            const v = await this.hexakaiPlusDailyPuzzleService.getPlaylistVideo(ts.ts) || "";
            const unlocked = await this.hexakaiPlusDailyPuzzleService.isDailyPuzzleUnlocked(ts.ts);
            this.renderTemplates.push({
                ach,
                ts,
                p: params,
                v,
                locked: !unlocked
            });
        }

        this.requestUpdate();
    }

    render() {
        if (this.renderTemplates.length === 0) {
            return html`<loading-display></loading-display>`;
        }

        return html`
            <style>
                ${DailyPuzzlesView.styles}
            </style>
            ${this.allPuzzlesUnlocked ? "" : html`<p>Some puzzles are locked. You can unlock all puzzles with a <a href="${this.plusHref}">Hexakai Plus</a> subscription.`}
            ${this.renderTemplates.map((t: RenderTemplate) => html`
                <div class="session-info-container ${t.locked ? "info-locked" : ""}" @click="${() => this.openSession(t)}">
                    <h3>${t.ts.ts === this.startTopTimestamp ? "Today's Puzzle - " : ""}Puzzle #${t.ts.no}</h3>
                    <div class="session-info">
                        <img-smart class="session-icon" src="${t.ach.iconUrl}"></img-smart>
                        <div>
                            <p><b>Date: </b>${new Date(t.ts.ts).toLocaleDateString()}
                            <p><b>Game Size: </b>${t.p.gameSize}</p>
                            <p><b>Difficulty: </b>${t.p.difficulty}</p>
                        </div>
                    </div>
                    <div class="session-buttons">
                        <styled-button @click="${() => this.openSession(t)}">Play Puzzle!</styled-button>
                        ${t.v ? html`<styled-button @click="${(e: any) => {
                            e.stopPropagation();
                            window.open(t.v, "_blank");
                        }}">Watch Playthrough</styled-button>` : ``}
                    </div>
                    <img class="info-icon" src="${t.locked ? "./lock.svg" : "./info.svg"}" @click="${(e: any) => {
                        e.stopPropagation();
                        if (t.locked) {
                            this.hexakaiPlusDailyPuzzleService.showLockedModal(t.ts.ts);
                        } else {
                            this.showAboutPuzzle(t);
                        }
                    }}" />
                </div>
            `)}
            <div id="puzzle-paginate">
                <styled-button style="${this.isStart ? 'display: none;' : ''}" @click="${() => this.goPrev()}">Previous</styled-button>
                <styled-button style="${this.isEnd ? 'display: none;' : ''}" @click="${() => this.goNext()}">Next</styled-button>
            </div>
        `;
    }

    private goPrev(): void {
        // if at the top, stop
        if (this.timestamps[0].ts >= this.startTopTimestamp) {
            return;
        }

        // update the positions and re-load data
        this.currentTopTimestamp =
            this.timestamps[0].ts + DailyPuzzleService.DAY_DURATION * this.NUM_PUZZLES_IN_VIEW;

        this.clientService.notifyInnerNavigationOccurred();
        (window as any).scrollTo({ top: 0, left: 0 });
        this.loadPuzzlesAndRender();
    }

    private goNext(): void {
        const offset = DailyPuzzleService.DAY_DURATION * this.NUM_PUZZLES_IN_VIEW;

        // if at the top, stop
        if (this.timestamps[0].ts - offset < DailyPuzzleService.FIRST_TIMESTAMP) {
            return;
        }

        // update the positions and re-load data
        this.currentTopTimestamp =
            this.timestamps[0].ts -= offset;

        this.clientService.notifyInnerNavigationOccurred();
        (window as any).scrollTo({ top: 0, left: 0 });
        this.loadPuzzlesAndRender();
    }

    private async openSession(t: RenderTemplate): Promise<void> {
        const isUnlocked = await this.hexakaiPlusDailyPuzzleService.isDailyPuzzleUnlocked(t.ts.ts);

        if (isUnlocked) {
            const dayString = DailyPuzzleService.getDateString(t.ts.ts);
            const url = this.clientService.createLocalLink(
                "app",
                {
                    d: dayString
                },
                true
            );

            window.location.href = url;
        } else {
            this.hexakaiPlusDailyPuzzleService.showLockedModal(t.ts.ts);
        }
    }

    private showAboutPuzzle(t: any): void {
        const modalId = 'daily-puzzle-info';
        console.log(`[DailyPuzzleView] info icon clicked`, t);

        const { gameSize, difficulty } = t.p;
        const difficultyMessage = (DIFFICULTY_TEXTS as any)[difficulty];

        const possibleValues = HexakaiCellValueMapper.getPossibleValues(gameSize, this.hexCellType);
        const gameSizeMessage = html`
            On a board of size ${gameSize}, there are ${gameSize} diagonals in each direction, and the center row is ${gameSize} cells wide,
            for a total of ${gameSize * gameSize} cells on the board. Each cell has a range of ${gameSize} values it can be assigned.
            On this board size, the possible values are: ${possibleValues.join(", ")}.
        `;

        const displayDate = new Date(t.ts.ts).toLocaleDateString();
        const modalTemplate = html`
            <div style="text-align: left">
                <p><b>The puzzle for ${displayDate} is in ${difficulty} mode on a board of size ${gameSize}.</b>
                <p>${difficultyMessage}</p>
                <p>${gameSizeMessage}
            </div>
            <br />
            <div style="display: flex; gap: 1em; flex-wrap: wrap">
                <styled-button @click="${() => this.modalService.hideModal(modalId)}">Close</styled-button>
                <styled-button @click="${() => {
                this.openSession(t.ts.ts);
            }}">Play Puzzle!</styled-button>
            </div>
        `;

        this.modalService.showModal(
            modalId,
            `Daily Puzzle Info`,
            modalTemplate
        );
    }
}