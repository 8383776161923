import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { HexakaiGameDifficulties, HexakaiGameDifficulty, HexakaiGameParams } from '../models/hexakai-game-params';
import { HexakaiBoardData } from '../hexakai-board/hexakai-board-data';
import { GENERATOR_PROFILE_DEFAULT, GENERATOR_PROFILE_LOOKUP, GENERATOR_PROFILES, GENERATOR_STANDARD, GeneratorPatternConfig } from '../hexakai-game/generator-patterns/generator-patterns';
import { HexakaiPlusFeatures } from '../hexakai-plus-subscription/hexakai-plus-features';
import { ComponentDependencyTree } from '../component-dependency-tree/component-dependency-tree';

@customElement('hexakai-game-settings')
export class HexakaiGameSettings extends LitElement {

    private clientService =  ComponentDependencyTree.clientService;

    private hexakaiPlusService = ComponentDependencyTree.hexakaiPlusSubscriptionService;

    private isLoading = true;
    private allowAdvanced = false;

    @property({ type: Object })
    settings: HexakaiGameParams = {
        gameSize: 10,
        difficulty: HexakaiGameDifficulty.easy,
        generatorId: GENERATOR_STANDARD.generatorId
    };

    static styles = css`
        details {
            background-color: var(--background-color);
            padding: 0.5em;
            transition: all 0.3s ease;
        }

        details summary {
            cursor: pointer;
            padding: 0.5em;
            outline: none;
            color: var(--body-color);
            list-style: none; /* Removes the default arrow */
            border-bottom: 1px solid var(--hex-cell-enabled-inner-border-color);
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center; /* Center aligns the content horizontally */
            text-align: center; /* Ensures text is centered */
        }

        details summary::-webkit-details-marker {
            display: none; /* Removes the default arrow in WebKit browsers */
        }

        details summary::before {
            content: '▶'; /* Custom closed indicator */
            margin-right: 0.5em;
            transition: transform 0.3s ease;
        }

        details[open] summary::before {
            content: '▼'; /* Custom open indicator */
        }

        details summary:hover {
            background-color: var(--hex-hover-color);
        }

        details .summary-text-open,
        details[open] .summary-text-closed {
            display: none;
        }

        details .summary-text-closed,
        details[open] .summary-text-open {
            display: inline;
        }

        .generators-container {
            height: -webkit-fill-available;
            overflow: auto;
            padding: 10px;
        }

        .generators-container {
            height: -webkit-fill-available;
            overflow: auto;
            padding: 10px;
        }

        .generator-box {
            display: flex;
            align-items: center;
            text-align: left;
            border: 1px solid var(--body-color);
            border-radius: 15px;
            padding: 1em;
            margin-bottom: 0.5em;
            cursor: pointer;
            transition: background-color 0.3s ease;
        }

        .box-disabled {
            cursor: not-allowed;
            filter: blur(1px);
        }

        .generator-box:hover {
            background-color: var(--input-border-color);
        }

        .box-selected {
            background-color: var(--input-border-color);
        }

        .box-disabled:hover {
            background-color: initial !important;
        }

        .generator-img {
            max-width: 15%;
            height: auto;
            aspect-ratio: 0.6;
            filter: drop-shadow(2px 4px 6px var(--body-color));
            margin-right: 1em;
        }

        .generator-description {
            flex: 1;
        }

        .generator-description h4 {
            margin-top: 0;
            color: var(--body-color);
        }

        .generator-description p {
            margin-bottom: 0;
            color: var(--body-color);
        }

        .size-diff-container {
            position: relative;
            display: flex;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 20px;
            gap: 1em;
            justify-content: center;
        }

        .size-diff {
            display: flex;
            flex-direction: column;
            text-align: left;
        }

        .size-diff select {
            background-color: var(--input-background-color);
            border: 1px solid var(--input-border-color);
            border-radius: 4px;
            padding: 8px;
            margin: 5px 0;
            color: var(--body-color);
            width: 8em;
        }

        /* Additional styles for other form elements if needed */
        input[type="text"],
        input[type="number"] {
            background-color: var(--input-background-color);
            border: 1px solid var(--input-border-color);
            border-radius: 4px;
            padding: 8px;
            margin: 5px 0;
            width: 100%;
            color: var(--body-color);
        }

        button {
            background-color: var(--button-background-color);
            color: var(--body-color);
            border: none;
            border-radius: 4px;
            padding: 10px 15px;
            margin-top: 10px;
            cursor: pointer;
            transition: background-color 0.3s ease;
        }

        button:hover {
            background-color: var(--button-hover-background-color);
        }
    `;

    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    connectedCallback(): void {
        super.connectedCallback();
        this.hexakaiPlusService.isFeatureUnlocked(HexakaiPlusFeatures.advancedBoardGeneration).then(isUnlocked => {
            this.isLoading = false;
            this.allowAdvanced = isUnlocked;
            this.requestUpdate();
        });
    }

    render() {
        let generator = html``;
        if (this.clientService.getConfig().gameBoard.generatorPatternCreate.enabled) {
            generator = this.renderGenerator();
        }

        return html`
            <style>
                ${HexakaiGameSettings.styles}
            </style>
            <div class="component-settings-container">
                <div class="size-diff-container">
                    <div class="size-diff">
                        <label for="game-size">Board Size:</label>
                        <select id="game-size" @change=${this.onGameSizeChange}>
                            ${HexakaiBoardData.GAME_SIZES.map(size => html`
                                <option value="${size}" ?selected=${size == this.settings.gameSize}>${size}</option>`
                            )}
                        </select>
                    </div>

                    <div class="size-diff">
                        <label for="game-difficulty">Difficulty:</label>
                        <select id="game-difficulty" @change=${this.onDifficultyChange}>
                            ${HexakaiGameDifficulties.map(diff => html`
                                <option value="${diff}" ?selected=${diff == this.settings.difficulty}>${diff}</option>`
                            )}
                        </select>
                    </div>
                </div>
                ${generator}
            </div>
        `;
    }

    private renderGenerator() {
        const profiles = GENERATOR_PROFILES.filter(p => {
            return p.allowedContexts.find(cxt => cxt.gameSize === this.settings.gameSize && cxt.difficulty === this.settings.difficulty)
        });

        // if only standard and random available, hide random
        let selectText: any = "Select the generator you want to use for this board.";
        if (profiles.length === 2) {
            profiles.splice(1);
            selectText = "Standard is currently the only option available. Try changing the board size and difficulty to select other options.";
        }

        if (!this.allowAdvanced) {
            const plusHref = this.clientService.createLocalLink("plus", {}, true);
            selectText = html`<i>This feature is only available with Hexakai Plus.</i><br>Click <a href="${plusHref}">here</a> to learn more.`;
        }

        const loadedTemplate = html`
            <p>${selectText}</p>
            <div class="generators-container">
                ${profiles.map(p => {
                    if ((p.valueRandomSeed as any)?.data) {
                        (p.valueRandomSeed as any).data.gameSize = this.settings.gameSize;
                    }
                    if ((p.hintRandomSeed as any)?.data) {
                        (p.hintRandomSeed as any).data.gameSize = this.settings.gameSize;
                    }

                    const id = `generator-box-${p.name.toLocaleLowerCase().replace(/ /g, "-")}`;
                    return html`
                        <div id="${id}" class="generator-box ${this.allowAdvanced ? "" : "box-disabled"} ${this.profilesEqual(p, this.settings) || profiles.length === 1 ? 'box-selected' : ''}"
                            @click="${() => this.allowAdvanced && this.onProfileClick(p)}"
                        >
                            <img-smart src="${p.img}" class="generator-img"></img-smart>
                            <div class="generator-description">
                                <h4>${p.name}</h4>
                                <p>${p.description}</p>
                            </div>
                        </div>
                    `;
                })}
            </div>
        `;

        const unloadedTemplate = html`
            <p>Loading...</p>
        `;

        const selections = this.isLoading
            ? unloadedTemplate
            : loadedTemplate;

        return html`
            <details id="show-hide-advanced-options">
                <summary>
                    <span class="summary-text-closed">Show Advanced Options</span>
                    <span class="summary-text-open">Hide Advanced Options</span>
                </summary>
                <div>
                    ${selections}
                </div>
            </details>
        `;
    }

    private profilesEqual(a: any, b: any): boolean {
        return a.generatorId === b.generatorId;
    }

    onGameSizeChange(event: Event): void {
        const select = event.target as HTMLSelectElement;
        const newSize = parseInt(select.value);
        console.log("[HexakaiGameSettings] updating game size to " + newSize);
        this.settings = { ...this.settings, gameSize: newSize };
        this.resetProfile();
    }

    onDifficultyChange(event: Event): void {
        const select = event.target as HTMLSelectElement;
        const newDifficulty = select.value as HexakaiGameDifficulty;
        console.log("[HexakaiGameSettings] updating difficulty to " + newDifficulty);
        this.settings = { ...this.settings, difficulty: newDifficulty };
        this.resetProfile();
    }

    onProfileClick(p: GeneratorPatternConfig): void {
        console.log("[HexakaiGameSettings] updating generator profile to ", p);
        this.settings = <HexakaiGameParams>{ 
            ...this.settings, 
            generatorId: p.generatorId
        };
    }

    getSettings(): HexakaiGameParams {
        return this.settings;
    }

    // if the selected profile is no longer available, reset to standard
    private resetProfile(): void {
        const profile = GENERATOR_PROFILE_LOOKUP[this.settings.generatorId || GENERATOR_PROFILE_DEFAULT.generatorId];
        const isValid = profile.allowedContexts.find(cxt => cxt.difficulty === this.settings.difficulty && cxt.gameSize === this.settings.gameSize);
        if (!isValid) {
            this.settings.generatorId = GENERATOR_PROFILE_DEFAULT.generatorId;
        }
    }
}
